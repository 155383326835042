html,
body {
  font-size: 16px;
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
.button {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  color: #FFF;
  background-color: #18191d;
  font-family: "Equip", sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  text-align: center;
  line-height: 1.2;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-size: 120% auto;
  transition: all 0.4s ease-in-out;
  white-space: nowrap;
  height: 50px;
}
@media screen and (max-width: 340px) {
  .button {
    padding: 15px 10px;
  }
}
.button:hover {
  background-position: right center;
  text-decoration: none;
  color: #fff;
}
.button:focus {
  outline: none;
}
.button:disabled {
  opacity: 0.5;
}
.button:not(:disabled) {
  cursor: pointer;
}
.button--slim {
  padding: 7px 10px;
}
.button--blue {
  background-image: linear-gradient(103.09deg, #1f93de 21.7%, #1571ad 59.43%);
}
.button--red {
  background-image: linear-gradient(103.09deg, #f60057 21.7%, #bb173e 59.43%);
}
.button--green {
  background-image: linear-gradient(103.09deg, #70c157 21.7%, #3e8c3b 59.43%);
}
.button--gray {
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
}
.button--black {
  background: #18191D;
}
.button--transparent-gray {
  color: #8B959E;
  border: 1px solid #CDD5DC;
  background: transparent;
}
.button--transparent-gray:hover {
  color: #8B959E;
}
.button--full-width {
  display: flex;
  width: 100%;
}
.button--inline {
  display: inline-block;
}
.button--img {
  justify-content: flex-start;
}
.button--img img {
  height: 17px;
  margin-right: 15px;
}
.button--text-start {
  text-align: start;
}
.button--faded {
  background: transparent;
}
.button--faded > * {
  opacity: 0.35;
}
@media screen and (max-width: 1119px) {
  .button--mobile-fullwidth {
    width: 100%;
  }
}
.button--in-header {
  height: 42px;
}
.button--with-dropdown-toggle {
  padding-right: calc(20px + 14px);
  justify-content: flex-start;
}
.button--with-dropdown-toggle:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: absolute;
  right: 20px;
}
.button--as-link {
  background: none;
  border: none;
  border-radius: 0;
  padding: 0;
  color: #1496cd;
  height: 0;
}
.button--as-link:hover {
  color: #12aaeb;
}