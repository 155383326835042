.container {
  text-align: center;
  width: 100%;
}

.dot {
  animation: dot-keyframes 1.5s infinite ease-in-out;
  background-color: white;
  border-radius: 10px;
  display: inline-block;
  height: 8px;
  width: 8px;
  margin: 0 2px;
}
.dot:nth-child(2) {
  animation-delay: 0.5s;
}
.dot:nth-child(3) {
  animation-delay: 1s;
}

@keyframes dot-keyframes {
  0% {
    opacity: 0.4;
    transform: scale(1, 1);
  }
  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }
  100% {
    opacity: 0.4;
    transform: scale(1, 1);
  }
}