.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  border-radius: 8px;
  color: white;
  flex-shrink: 0;
  flex-grow: 0;
  width: max-content;
  border: 1px solid transparent;
  padding: 12px 24px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  cursor: pointer;
}
.button:hover {
  color: white;
}
.button--flexible {
  width: unset;
  flex-grow: 1;
}
.button--condensed-all {
  padding: 8px 16px;
}
.button--condensed-horizontal {
  padding-left: 16px;
  padding-right: 16px;
}
.button--alignStart {
  justify-content: flex-start;
}
.button--textSizeSmall {
  font-size: 12px;
  line-height: 16px;
}
.button--primary {
  background-image: linear-gradient(90deg, #44BBFE 0%, #1E78FE 100%);
  background-origin: border-box;
  background-size: 200% auto;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  transition: background 0.3s ease-in-out;
  border-color: rgba(255, 255, 255, 0.3);
}
.button--primary:hover, .button--primary:focus-visible {
  background-position: 80%;
}
.button--primary:focus-visible {
  border-color: #FFFFFF;
}
.button--danger {
  background-image: linear-gradient(90deg, #E10000 0%, #AA0303 100%);
  background-origin: border-box;
  background-size: 200% auto;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  transition: background 0.3s ease-in-out;
  border-color: rgba(255, 255, 255, 0.3);
}
.button--danger:hover, .button--danger:focus-visible {
  background-position: 80%;
}
.button--danger:focus-visible {
  border-color: #FFFFFF;
}
.button--success {
  background-image: linear-gradient(90deg, #68FD44 -43.21%, #46AE2C 59.57%);
  background-origin: border-box;
  background-size: 200% auto;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  transition: background 0.3s ease-in-out;
  border-color: rgba(255, 255, 255, 0.3);
}
.button--success:hover, .button--success:focus-visible {
  background-position: 80%;
}
.button--success:focus-visible {
  border-color: #FFFFFF;
}
.button--secondary {
  background: #1A1B1F;
  background-origin: border-box;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  transition: background-color, border 0.2s ease-out;
}
.button--secondary:hover {
  background-color: #2B2E38;
  border-color: #84899C;
  color: white;
}
.button--secondary:focus-visible {
  border-color: #2B2E38;
}
@media (max-width: 767px) {
  .button--secondary {
    background-color: #2B2E38;
  }
  .button--secondary:hover {
    background-color: #1A1B1F;
  }
}
.button--disabled {
  opacity: 0.7;
  cursor: initial;
}
.button--disabled:hover {
  background-position: initial;
}

.endIconWrapper {
  margin-left: 16px;
}