:root {
  /* Colors */
  --background-black: #1A1B1F;
  --border-gray: #3D3D3D;
  --dark-gray: #2E303B;
  --light-gray: #353946;
  --lighter-gray: #2B2E38;
  --lightest-gray: #84899C;
  --faded-white: #6C6C6C;
  --blue: #12AAEB;
  --faded-blue: #21232B;
  --light-blue: #44BBFE;
  --dark-blue: #003C56;
  --dark-red: #E10000;
  --darker-red: #411417;
  --border-white: #32343D;
  --light-green: #68FD44;
  --yellow: #FFB200;
  --orange: #E3A300;
  --loyalty-bronze: #E36629;
  --loyalty-silver: #BCB8B7;
  --loyalty-gold: #F8A300;
  --loyalty-platinum: #EEEEEE;
  --loyalty-diamond: #A3F4FF;
  --buy-color: #55D636;
  --sell-color: #CD0000;
  /* Sizes */
  --header-height: 88px;
  --header-item-height: 60px;
  --mobile-header-height: 56px;
  --mobile-header-item-height: 40px;
  /* Icon sizes */
  --icon-size-m: 24px;
  /* Gradients */
  --gray-gradient: linear-gradient(180deg, #2E303B 0%, #21232B 100%);
  --blue-gradient: linear-gradient(90deg, #44BBFE 0%, #1E78FE 100%);
  --red-gradient: linear-gradient(90deg, #E10000 0%, #AA0303 100%);
  --green-gradient: linear-gradient(90deg, #68FD44 -43.21%, #46AE2C 59.57%);
  /* Spacings */
  --spacer-xxs: 4px;
  --spacer-xs: 8px;
  --spacer-s: 12px;
  --spacer-m: 16px;
  --spacer-l: 24px;
  --spacer-xl: 32px;
  /* Border radius */
  --radius-m: 8px;
  /* Typography */
  --font-prim: "Plus Jakarta Sans", sans-serif;
  --text-color: white;
  --weight-thin: 100;
  --weight-extra-light: 200;
  --weight-light: 300;
  --weight-normal: 400;
  --weight-medium: 500;
  --weight-semi-bold: 600;
  --weight-bold: 700;
  --weight-extra-bold: 800;
  --weight-heavy: 900;
  --heading-xl: 42px;
  --heading-large: 30px;
  --heading: 24px;
  --heading-s: 20px;
  --title: 18px;
  --body: 16px;
  --label: 14px;
  --caption: 12px;
  --caption-small: 10px;
  --body-line-height: 24px;
  --heading-line-height: 32px;
  --label-line-height: 24px;
  --caption-line-height: 16px;
  /* Breakpoints */
  --xs: 479px;
  --sm: 480px;
  --md-sm: 600px;
  --md: 768px;
  --lg: 980px;
  --desktop: 1024px;
  --xlg: 1200px;
  --xxlg: 1366px;
  --xxxlg: 1920px;
  /* Z-indexes */
  --zindex-dropdown: 1000;
  --zindex-sticky: 1020;
  --zindex-fixed: 1030;
  --zindex-modal-backdrop: 1040;
  --zindex-modal: 1050;
  --zindex-popover: 1060;
  --zindex-tooltip: 1070;
}

:root {
  /* Colors */
  --background-black: #1A1B1F;
  --border-gray: #3D3D3D;
  --dark-gray: #2E303B;
  --light-gray: #353946;
  --lighter-gray: #2B2E38;
  --lightest-gray: #84899C;
  --faded-white: #6C6C6C;
  --blue: #12AAEB;
  --faded-blue: #21232B;
  --light-blue: #44BBFE;
  --dark-blue: #003C56;
  --dark-red: #E10000;
  --darker-red: #411417;
  --border-white: #32343D;
  --light-green: #68FD44;
  --yellow: #FFB200;
  --orange: #E3A300;
  --loyalty-bronze: #E36629;
  --loyalty-silver: #BCB8B7;
  --loyalty-gold: #F8A300;
  --loyalty-platinum: #EEEEEE;
  --loyalty-diamond: #A3F4FF;
  --buy-color: #55D636;
  --sell-color: #CD0000;
  /* Sizes */
  --header-height: 88px;
  --header-item-height: 60px;
  --mobile-header-height: 56px;
  --mobile-header-item-height: 40px;
  /* Icon sizes */
  --icon-size-m: 24px;
  /* Gradients */
  --gray-gradient: linear-gradient(180deg, #2E303B 0%, #21232B 100%);
  --blue-gradient: linear-gradient(90deg, #44BBFE 0%, #1E78FE 100%);
  --red-gradient: linear-gradient(90deg, #E10000 0%, #AA0303 100%);
  --green-gradient: linear-gradient(90deg, #68FD44 -43.21%, #46AE2C 59.57%);
  /* Spacings */
  --spacer-xxs: 4px;
  --spacer-xs: 8px;
  --spacer-s: 12px;
  --spacer-m: 16px;
  --spacer-l: 24px;
  --spacer-xl: 32px;
  /* Border radius */
  --radius-m: 8px;
  /* Typography */
  --font-prim: "Plus Jakarta Sans", sans-serif;
  --text-color: white;
  --weight-thin: 100;
  --weight-extra-light: 200;
  --weight-light: 300;
  --weight-normal: 400;
  --weight-medium: 500;
  --weight-semi-bold: 600;
  --weight-bold: 700;
  --weight-extra-bold: 800;
  --weight-heavy: 900;
  --heading-xl: 42px;
  --heading-large: 30px;
  --heading: 24px;
  --heading-s: 20px;
  --title: 18px;
  --body: 16px;
  --label: 14px;
  --caption: 12px;
  --caption-small: 10px;
  --body-line-height: 24px;
  --heading-line-height: 32px;
  --label-line-height: 24px;
  --caption-line-height: 16px;
  /* Breakpoints */
  --xs: 479px;
  --sm: 480px;
  --md-sm: 600px;
  --md: 768px;
  --lg: 980px;
  --desktop: 1024px;
  --xlg: 1200px;
  --xxlg: 1366px;
  --xxxlg: 1920px;
  /* Z-indexes */
  --zindex-dropdown: 1000;
  --zindex-sticky: 1020;
  --zindex-fixed: 1030;
  --zindex-modal-backdrop: 1040;
  --zindex-modal: 1050;
  --zindex-popover: 1060;
  --zindex-tooltip: 1070;
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  border-radius: 8px;
  color: white;
  flex-shrink: 0;
  flex-grow: 0;
  width: max-content;
  border: 1px solid transparent;
  padding: 12px 24px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.button:hover {
  color: white;
}
.button--flexible {
  width: unset;
  flex-grow: 1;
}
.button--condensed-all {
  padding: 8px 16px;
}
.button--condensed-horizontal {
  padding-left: 16px;
  padding-right: 16px;
}
.button--alignStart {
  justify-content: flex-start;
}
.button--textSizeSmall {
  font-size: 12px;
  line-height: 16px;
}
.button--primary {
  background-image: linear-gradient(90deg, #44BBFE 0%, #1E78FE 100%);
  background-origin: border-box;
  background-size: 200% auto;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  transition: background 0.3s ease-in-out;
  border-color: rgba(255, 255, 255, 0.3);
}
.button--primary:hover, .button--primary:focus-visible {
  background-position: 80%;
}
.button--primary:focus-visible {
  border-color: #FFFFFF;
}
.button--danger {
  background-image: linear-gradient(90deg, #E10000 0%, #AA0303 100%);
  background-origin: border-box;
  background-size: 200% auto;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  transition: background 0.3s ease-in-out;
  border-color: rgba(255, 255, 255, 0.3);
}
.button--danger:hover, .button--danger:focus-visible {
  background-position: 80%;
}
.button--danger:focus-visible {
  border-color: #FFFFFF;
}
.button--success {
  background-image: linear-gradient(90deg, #46AE2C 0%, #3e8c3b 100%);
  background-origin: border-box;
  background-size: 200% auto;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  transition: background 0.3s ease-in-out;
  border-color: rgba(255, 255, 255, 0.3);
}
.button--success:hover, .button--success:focus-visible {
  background-position: 80%;
}
.button--success:focus-visible {
  border-color: #FFFFFF;
}
.button--secondary {
  background: #1a1b1f;
  background-origin: border-box;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  transition: background-color, border 0.2s ease-out;
}
.button--secondary:hover {
  background-color: #2b2e38;
  border-color: #84899c;
  color: white;
}
.button--secondary:focus-visible {
  border-color: #2b2e38;
}
@media (max-width: 767px) {
  .button--secondary {
    background-color: #2b2e38;
  }
  .button--secondary:hover {
    background-color: #1a1b1f;
  }
}
.button--disabled {
  opacity: 0.7;
}

.endIconWrapper {
  margin-left: 16px;
}