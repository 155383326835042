:root {
  /* Colors */
  --background-black: #1A1B1F;
  --border-gray: #3D3D3D;
  --dark-gray: #2E303B;
  --light-gray: #353946;
  --lighter-gray: #2B2E38;
  --lightest-gray: #84899C;
  --faded-white: #6C6C6C;
  --blue: #12AAEB;
  --faded-blue: #21232B;
  --light-blue: #44BBFE;
  --dark-blue: #003C56;
  --dark-red: #E10000;
  --darker-red: #411417;
  --border-white: #32343D;
  --light-green: #68FD44;
  --yellow: #FFB200;
  --orange: #E3A300;
  --loyalty-bronze: #E36629;
  --loyalty-silver: #BCB8B7;
  --loyalty-gold: #F8A300;
  --loyalty-platinum: #EEEEEE;
  --loyalty-diamond: #A3F4FF;
  --buy-color: #55D636;
  --sell-color: #CD0000;
  /* Sizes */
  --header-height: 88px;
  --header-item-height: 60px;
  --mobile-header-height: 56px;
  --mobile-header-item-height: 40px;
  /* Icon sizes */
  --icon-size-m: 24px;
  /* Gradients */
  --gray-gradient: linear-gradient(180deg, #2E303B 0%, #21232B 100%);
  --blue-gradient: linear-gradient(90deg, #44BBFE 0%, #1E78FE 100%);
  --red-gradient: linear-gradient(90deg, #E10000 0%, #AA0303 100%);
  --green-gradient: linear-gradient(90deg, #68FD44 -43.21%, #46AE2C 59.57%);
  /* Spacings */
  --spacer-xxs: 4px;
  --spacer-xs: 8px;
  --spacer-s: 12px;
  --spacer-m: 16px;
  --spacer-l: 24px;
  --spacer-xl: 32px;
  /* Border radius */
  --radius-m: 8px;
  /* Typography */
  --font-prim: "Plus Jakarta Sans", sans-serif;
  --text-color: white;
  --weight-thin: 100;
  --weight-extra-light: 200;
  --weight-light: 300;
  --weight-normal: 400;
  --weight-medium: 500;
  --weight-semi-bold: 600;
  --weight-bold: 700;
  --weight-extra-bold: 800;
  --weight-heavy: 900;
  --heading-xl: 42px;
  --heading-large: 30px;
  --heading: 24px;
  --heading-s: 20px;
  --title: 18px;
  --body: 16px;
  --label: 14px;
  --caption: 12px;
  --caption-small: 10px;
  --body-line-height: 24px;
  --heading-line-height: 32px;
  --label-line-height: 24px;
  --caption-line-height: 16px;
  /* Breakpoints */
  --xs: 479px;
  --sm: 480px;
  --md-sm: 600px;
  --md: 768px;
  --lg: 980px;
  --desktop: 1024px;
  --xlg: 1200px;
  --xxlg: 1366px;
  --xxxlg: 1920px;
  /* Z-indexes */
  --zindex-dropdown: 1000;
  --zindex-sticky: 1020;
  --zindex-fixed: 1030;
  --zindex-modal-backdrop: 1040;
  --zindex-modal: 1050;
  --zindex-popover: 1060;
  --zindex-tooltip: 1070;
}

:root {
  /* Colors */
  --background-black: #1A1B1F;
  --border-gray: #3D3D3D;
  --dark-gray: #2E303B;
  --light-gray: #353946;
  --lighter-gray: #2B2E38;
  --lightest-gray: #84899C;
  --faded-white: #6C6C6C;
  --blue: #12AAEB;
  --faded-blue: #21232B;
  --light-blue: #44BBFE;
  --dark-blue: #003C56;
  --dark-red: #E10000;
  --darker-red: #411417;
  --border-white: #32343D;
  --light-green: #68FD44;
  --yellow: #FFB200;
  --orange: #E3A300;
  --loyalty-bronze: #E36629;
  --loyalty-silver: #BCB8B7;
  --loyalty-gold: #F8A300;
  --loyalty-platinum: #EEEEEE;
  --loyalty-diamond: #A3F4FF;
  --buy-color: #55D636;
  --sell-color: #CD0000;
  /* Sizes */
  --header-height: 88px;
  --header-item-height: 60px;
  --mobile-header-height: 56px;
  --mobile-header-item-height: 40px;
  /* Icon sizes */
  --icon-size-m: 24px;
  /* Gradients */
  --gray-gradient: linear-gradient(180deg, #2E303B 0%, #21232B 100%);
  --blue-gradient: linear-gradient(90deg, #44BBFE 0%, #1E78FE 100%);
  --red-gradient: linear-gradient(90deg, #E10000 0%, #AA0303 100%);
  --green-gradient: linear-gradient(90deg, #68FD44 -43.21%, #46AE2C 59.57%);
  /* Spacings */
  --spacer-xxs: 4px;
  --spacer-xs: 8px;
  --spacer-s: 12px;
  --spacer-m: 16px;
  --spacer-l: 24px;
  --spacer-xl: 32px;
  /* Border radius */
  --radius-m: 8px;
  /* Typography */
  --font-prim: "Plus Jakarta Sans", sans-serif;
  --text-color: white;
  --weight-thin: 100;
  --weight-extra-light: 200;
  --weight-light: 300;
  --weight-normal: 400;
  --weight-medium: 500;
  --weight-semi-bold: 600;
  --weight-bold: 700;
  --weight-extra-bold: 800;
  --weight-heavy: 900;
  --heading-xl: 42px;
  --heading-large: 30px;
  --heading: 24px;
  --heading-s: 20px;
  --title: 18px;
  --body: 16px;
  --label: 14px;
  --caption: 12px;
  --caption-small: 10px;
  --body-line-height: 24px;
  --heading-line-height: 32px;
  --label-line-height: 24px;
  --caption-line-height: 16px;
  /* Breakpoints */
  --xs: 479px;
  --sm: 480px;
  --md-sm: 600px;
  --md: 768px;
  --lg: 980px;
  --desktop: 1024px;
  --xlg: 1200px;
  --xxlg: 1366px;
  --xxxlg: 1920px;
  /* Z-indexes */
  --zindex-dropdown: 1000;
  --zindex-sticky: 1020;
  --zindex-fixed: 1030;
  --zindex-modal-backdrop: 1040;
  --zindex-modal: 1050;
  --zindex-popover: 1060;
  --zindex-tooltip: 1070;
}

.toast {
  background-color: #21232b;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 18px 48px rgba(0, 0, 0, 0.5);
  width: 100%;
  max-width: 100%;
  display: flex;
  gap: 16px;
}
.toast--success {
  background: linear-gradient(90deg, rgba(104, 253, 68, 0.22) 0%, rgba(104, 253, 68, 0.1) 15%, rgba(104, 253, 68, 0) 50%), #21232b;
  --toast-accent-color: #68fd44;
}
.toast--error {
  background: linear-gradient(90deg, rgba(215, 1, 1, 0.22) 0%, rgba(215, 1, 1, 0.1) 15%, rgba(217, 1, 1, 0) 50%), #21232b;
  --toast-accent-color: #e10000 ;
}
.toast--warning {
  background: linear-gradient(90deg, rgba(227, 163, 0, 0.22) 0%, rgba(227, 163, 0, 0.1) 15%, rgba(227, 163, 0, 0) 50%), #21232b;
  --toast-accent-color: #e3a300;
}
.toast[data-state=open] {
  animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.toast[data-state=closed] {
  animation: swipeOut 100ms ease-out;
}
.toast[data-swipe=move] {
  transform: translateX(var(--radix-toast-swipe-move-x));
}
.toast[data-swipe=cancel] {
  transform: translateX(0);
  transition: transform 200ms ease-out;
}
.toast[data-swipe=end] {
  animation: swipeOut 100ms ease-out;
}

.titleDescriptionWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-grow: 1;
  justify-content: center;
}

.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--toast-accent-color);
}

.description {
  font-size: 12px;
  line-height: 16px;
  color: #84899c;
}

.iconWrapper {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  color: var(--toast-accent-color);
}

@keyframes slideIn {
  from {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
  to {
    transform: translateX(0);
  }
}
@keyframes swipeOut {
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }
  to {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
}