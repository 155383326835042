.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.container .title {
  margin-bottom: 1rem;
  color: white;
}
.container .description {
  margin-bottom: 0;
  text-align: center;
}
.container .version-container {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.container .version-container span {
  display: block;
  text-align: right;
}