html,
body {
  font-size: 16px;
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
.table {
  border-collapse: separate;
  border-spacing: 0 0.2rem;
}
.table thead {
  color: rgba(255, 255, 255, 0.5);
  background-color: #2a2e31;
}
.table th {
  font-size: 0.75rem;
  font-weight: 500;
}
.table tbody {
  font-size: 0.875rem;
  font-weight: 300;
}
.table td, .table th {
  text-align: center;
  padding: 0.7rem 0;
}
@media screen and (max-width: 1119px) {
  .table td, .table th {
    padding: 0.5rem 0;
  }
}
.table td:first-child {
  text-align: start;
}
.table td:first-child, .table th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.table td:last-child, .table th:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.table tbody tr {
  border-radius: 5px;
  margin-bottom: 0.5rem;
}
.table tbody tr:nth-child(even) {
  background: #2c3036;
}
.table tbody tr:nth-child(odd) {
  background: #24262A;
}

.crown-icon {
  margin-left: 1rem;
  margin-right: 0.5rem;
}
@media screen and (max-width: 1119px) {
  .crown-icon {
    margin-left: 0;
  }
}

.title, .subtitle {
  text-align: center;
}

.subtitle {
  color: #A7A8A8;
}