html,
body {
  font-size: 16px;
}

/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
.container {
  position: relative;
}
.container:not(.container-active) .icon {
  color: #A7A8A8;
}
.container:not(.container-active) .label {
  color: #A7A8A8;
}
.container-active {
  color: inherit;
}

.label {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 0.6875rem;
  font-weight: 300;
  color: #FFF;
}