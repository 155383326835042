.container {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}

.toastViewport {
  --viewport-padding: 25px;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: var(--viewport-padding);
  gap: 10px;
  width: 390px;
  max-width: 100vw;
  margin: 0;
  list-style: none;
  z-index: 2147483647;
  outline: none;
}