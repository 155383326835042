html,
body {
  font-size: 16px;
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 30px;
  max-height: 100%;
  overflow: hidden;
  align-self: center;
  width: 100%;
  max-width: 1020px !important;
}

.tabs-wrapper {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  height: 100%;
  overflow: hidden;
}