.container {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1030;
}
.container--inline {
  position: relative;
  width: 24px;
  height: 24px;
}
.container--inline .lds-ring div {
  width: 24px;
  height: 24px;
  border-width: 3px;
  margin: 8px;
}
.container .loader-background {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  opacity: 1;
}
.container .loader-background--opaque {
  background-color: #1A1B1F;
  opacity: 1;
}
.container .loader-background--semi-transparent {
  background-color: #1A1B1F;
  opacity: 0.8;
}

.lds-ring {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid transparent;
  border-top-color: #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}