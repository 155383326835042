html,
body {
  font-size: 16px;
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
.container {
  width: 68px;
  height: 68px;
  border-radius: 34px;
  background: linear-gradient(134.22deg, #565a5c -63.52%, #2a2e31 74.66%);
  box-shadow: 3px 0px 5px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 40%;
  object-fit: contain;
}

.label {
  font-size: 0.6875rem;
}