@charset "UTF-8";
/**************************************************
    MAIN STYLES
    **********************************************/
/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
/**************************************************
    LIBS & INCLUDES (CORE)
    **********************************************/
:root {
  /* Colors */
  --background-black: #1A1B1F;
  --border-gray: #3D3D3D;
  --dark-gray: #2E303B;
  --light-gray: #353946;
  --lighter-gray: #2B2E38;
  --lightest-gray: #84899C;
  --faded-white: #6C6C6C;
  --blue: #12AAEB;
  --faded-blue: #21232B;
  --light-blue: #44BBFE;
  --dark-blue: #003C56;
  --dark-red: #E10000;
  --darker-red: #411417;
  --border-white: #32343D;
  --light-green: #68FD44;
  --yellow: #FFB200;
  --orange: #E3A300;
  --loyalty-bronze: #E36629;
  --loyalty-silver: #BCB8B7;
  --loyalty-gold: #F8A300;
  --loyalty-platinum: #EEEEEE;
  --loyalty-diamond: #A3F4FF;
  --buy-color: #55D636;
  --sell-color: #CD0000;
  /* Sizes */
  --header-height: 88px;
  --header-item-height: 60px;
  --mobile-header-height: 56px;
  --mobile-header-item-height: 40px;
  /* Icon sizes */
  --icon-size-m: 24px;
  /* Gradients */
  --gray-gradient: linear-gradient(180deg, #2E303B 0%, #21232B 100%);
  --blue-gradient: linear-gradient(90deg, #44BBFE 0%, #1E78FE 100%);
  --red-gradient: linear-gradient(90deg, #E10000 0%, #AA0303 100%);
  --green-gradient: linear-gradient(90deg, #68FD44 -43.21%, #46AE2C 59.57%);
  /* Spacings */
  --spacer-xxs: 4px;
  --spacer-xs: 8px;
  --spacer-s: 12px;
  --spacer-m: 16px;
  --spacer-l: 24px;
  --spacer-xl: 32px;
  /* Border radius */
  --radius-m: 8px;
  /* Typography */
  --font-prim: "Plus Jakarta Sans", sans-serif;
  --text-color: white;
  --weight-thin: 100;
  --weight-extra-light: 200;
  --weight-light: 300;
  --weight-normal: 400;
  --weight-medium: 500;
  --weight-semi-bold: 600;
  --weight-bold: 700;
  --weight-extra-bold: 800;
  --weight-heavy: 900;
  --heading-xl: 42px;
  --heading-large: 30px;
  --heading: 24px;
  --heading-s: 20px;
  --title: 18px;
  --body: 16px;
  --label: 14px;
  --caption: 12px;
  --caption-small: 10px;
  --body-line-height: 24px;
  --heading-line-height: 32px;
  --label-line-height: 24px;
  --caption-line-height: 16px;
  /* Breakpoints */
  --xs: 479px;
  --sm: 480px;
  --md-sm: 600px;
  --md: 768px;
  --lg: 980px;
  --desktop: 1024px;
  --xlg: 1200px;
  --xxlg: 1366px;
  --xxxlg: 1920px;
  /* Z-indexes */
  --zindex-dropdown: 1000;
  --zindex-sticky: 1020;
  --zindex-fixed: 1030;
  --zindex-modal-backdrop: 1040;
  --zindex-modal: 1050;
  --zindex-popover: 1060;
  --zindex-tooltip: 1070;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: white;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 800;
}

small {
  font-size: 0.8rem;
}

sub,
sup {
  position: relative;
  font-size: 0.75rem;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #12aaeb;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #003c56;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1rem;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/**************************************************
    MODULES
    **********************************************/
:root {
  /* Colors */
  --background-black: #1A1B1F;
  --border-gray: #3D3D3D;
  --dark-gray: #2E303B;
  --light-gray: #353946;
  --lighter-gray: #2B2E38;
  --lightest-gray: #84899C;
  --faded-white: #6C6C6C;
  --blue: #12AAEB;
  --faded-blue: #21232B;
  --light-blue: #44BBFE;
  --dark-blue: #003C56;
  --dark-red: #E10000;
  --darker-red: #411417;
  --border-white: #32343D;
  --light-green: #68FD44;
  --yellow: #FFB200;
  --orange: #E3A300;
  --loyalty-bronze: #E36629;
  --loyalty-silver: #BCB8B7;
  --loyalty-gold: #F8A300;
  --loyalty-platinum: #EEEEEE;
  --loyalty-diamond: #A3F4FF;
  --buy-color: #55D636;
  --sell-color: #CD0000;
  /* Sizes */
  --header-height: 88px;
  --header-item-height: 60px;
  --mobile-header-height: 56px;
  --mobile-header-item-height: 40px;
  /* Icon sizes */
  --icon-size-m: 24px;
  /* Gradients */
  --gray-gradient: linear-gradient(180deg, #2E303B 0%, #21232B 100%);
  --blue-gradient: linear-gradient(90deg, #44BBFE 0%, #1E78FE 100%);
  --red-gradient: linear-gradient(90deg, #E10000 0%, #AA0303 100%);
  --green-gradient: linear-gradient(90deg, #68FD44 -43.21%, #46AE2C 59.57%);
  /* Spacings */
  --spacer-xxs: 4px;
  --spacer-xs: 8px;
  --spacer-s: 12px;
  --spacer-m: 16px;
  --spacer-l: 24px;
  --spacer-xl: 32px;
  /* Border radius */
  --radius-m: 8px;
  /* Typography */
  --font-prim: "Plus Jakarta Sans", sans-serif;
  --text-color: white;
  --weight-thin: 100;
  --weight-extra-light: 200;
  --weight-light: 300;
  --weight-normal: 400;
  --weight-medium: 500;
  --weight-semi-bold: 600;
  --weight-bold: 700;
  --weight-extra-bold: 800;
  --weight-heavy: 900;
  --heading-xl: 42px;
  --heading-large: 30px;
  --heading: 24px;
  --heading-s: 20px;
  --title: 18px;
  --body: 16px;
  --label: 14px;
  --caption: 12px;
  --caption-small: 10px;
  --body-line-height: 24px;
  --heading-line-height: 32px;
  --label-line-height: 24px;
  --caption-line-height: 16px;
  /* Breakpoints */
  --xs: 479px;
  --sm: 480px;
  --md-sm: 600px;
  --md: 768px;
  --lg: 980px;
  --desktop: 1024px;
  --xlg: 1200px;
  --xxlg: 1366px;
  --xxxlg: 1920px;
  /* Z-indexes */
  --zindex-dropdown: 1000;
  --zindex-sticky: 1020;
  --zindex-fixed: 1030;
  --zindex-modal-backdrop: 1040;
  --zindex-modal: 1050;
  --zindex-popover: 1060;
  --zindex-tooltip: 1070;
}

:root {
  /* Colors */
  --background-black: #1A1B1F;
  --border-gray: #3D3D3D;
  --dark-gray: #2E303B;
  --light-gray: #353946;
  --lighter-gray: #2B2E38;
  --lightest-gray: #84899C;
  --faded-white: #6C6C6C;
  --blue: #12AAEB;
  --faded-blue: #21232B;
  --light-blue: #44BBFE;
  --dark-blue: #003C56;
  --dark-red: #E10000;
  --darker-red: #411417;
  --border-white: #32343D;
  --light-green: #68FD44;
  --yellow: #FFB200;
  --orange: #E3A300;
  --loyalty-bronze: #E36629;
  --loyalty-silver: #BCB8B7;
  --loyalty-gold: #F8A300;
  --loyalty-platinum: #EEEEEE;
  --loyalty-diamond: #A3F4FF;
  --buy-color: #55D636;
  --sell-color: #CD0000;
  /* Sizes */
  --header-height: 88px;
  --header-item-height: 60px;
  --mobile-header-height: 56px;
  --mobile-header-item-height: 40px;
  /* Icon sizes */
  --icon-size-m: 24px;
  /* Gradients */
  --gray-gradient: linear-gradient(180deg, #2E303B 0%, #21232B 100%);
  --blue-gradient: linear-gradient(90deg, #44BBFE 0%, #1E78FE 100%);
  --red-gradient: linear-gradient(90deg, #E10000 0%, #AA0303 100%);
  --green-gradient: linear-gradient(90deg, #68FD44 -43.21%, #46AE2C 59.57%);
  /* Spacings */
  --spacer-xxs: 4px;
  --spacer-xs: 8px;
  --spacer-s: 12px;
  --spacer-m: 16px;
  --spacer-l: 24px;
  --spacer-xl: 32px;
  /* Border radius */
  --radius-m: 8px;
  /* Typography */
  --font-prim: "Plus Jakarta Sans", sans-serif;
  --text-color: white;
  --weight-thin: 100;
  --weight-extra-light: 200;
  --weight-light: 300;
  --weight-normal: 400;
  --weight-medium: 500;
  --weight-semi-bold: 600;
  --weight-bold: 700;
  --weight-extra-bold: 800;
  --weight-heavy: 900;
  --heading-xl: 42px;
  --heading-large: 30px;
  --heading: 24px;
  --heading-s: 20px;
  --title: 18px;
  --body: 16px;
  --label: 14px;
  --caption: 12px;
  --caption-small: 10px;
  --body-line-height: 24px;
  --heading-line-height: 32px;
  --label-line-height: 24px;
  --caption-line-height: 16px;
  /* Breakpoints */
  --xs: 479px;
  --sm: 480px;
  --md-sm: 600px;
  --md: 768px;
  --lg: 980px;
  --desktop: 1024px;
  --xlg: 1200px;
  --xxlg: 1366px;
  --xxxlg: 1920px;
  /* Z-indexes */
  --zindex-dropdown: 1000;
  --zindex-sticky: 1020;
  --zindex-fixed: 1030;
  --zindex-modal-backdrop: 1040;
  --zindex-modal: 1050;
  --zindex-popover: 1060;
  --zindex-tooltip: 1070;
}

html,
body {
  font-size: 16px;
  line-height: 24px;
  font-family: "Plus Jakarta Sans", sans-serif;
}

p {
  font-size: 16px;
  line-height: 24px;
}

span {
  font-size: 16px;
  line-height: 24px;
}

h1 {
  font-size: 42px;
  font-weight: 700;
}

h2 {
  font-size: 30px;
  font-weight: 700;
}

h3 {
  font-size: 30px;
  font-weight: 700;
}

h4 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}

h5 {
  font-size: 20px;
  font-weight: 700;
}

h6 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

:root {
  color-scheme: dark;
}

* {
  margin: 0;
  padding: 0;
}

html, body {
  overflow: hidden;
}

a {
  text-decoration: none;
  outline: none;
}
a:hover {
  text-decoration: none;
}

button {
  outline: none;
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/* Only Chrome */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(93, 94, 97, 0.5);
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 20px;
}

/* IE */
html {
  scrollbar-face-color: rgba(93, 94, 97, 0.5);
  scrollbar-shadow-color: transparent;
  scrollbar-highlight-color: transparent;
}

/* FF */
html {
  scrollbar-color: rgba(93, 94, 97, 0.5) transparent;
}

fieldset {
  margin: 0;
  padding: 0;
}

input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input[type=text], input[type=email], input[type=search],
input[type=password], textarea {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  outline: none !important;
}

.form {
  position: relative;
}
.form__fieldset {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 0.5rem 0.5rem;
  padding: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
}
.form__fieldset:focus-within::after {
  background: #70c157;
}
.form__fieldset::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #383d44;
  height: 2px;
}
.form__fieldset--condensed {
  margin: 0;
}
.form__fieldset label.error {
  position: relative;
  left: 20px;
  margin: 0;
  color: #f60057;
  font-size: 12px;
  text-transform: uppercase;
}
.form__input-box {
  position: relative;
}
.form__input-suffix-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 15px;
  height: auto;
}
.form__label {
  position: relative;
  display: block;
  margin-top: 0.5rem;
  padding: 0 20px;
  font-size: 12px;
  text-align: center;
}
.form__label--radio, .form__label--checkbox {
  display: inline;
  margin: 0 0 0 0.5rem;
  padding: 0;
  text-transform: none;
}
.form__label--radio a, .form__label--checkbox a {
  color: #12aaeb;
  border-bottom: 1px solid transparent !important;
}
.form__label--radio a:hover, .form__label--checkbox a:hover {
  border-color: #12aaeb;
}
.form__label--radio i, .form__label--checkbox i {
  float: left;
  position: relative;
  top: 2px;
}
.form__label--radio span, .form__label--checkbox span {
  display: block;
  margin-left: 25px;
}
.form__textfield, .form__fieldset input:not([type=checkbox]):not([type=radio]) {
  margin: 0;
  padding: 0 20px;
  width: 100%;
  border: none;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
  background: transparent;
  position: relative;
  background: #18191d;
  border-radius: 5px;
  height: 50px;
}
.form__textfield--small, .form__fieldset--small input:not([type=checkbox]):not([type=radio]) {
  height: 30px;
  font-size: 12px;
  padding: 0 10px;
}
.form__textfield--text-center, .form__fieldset--text-center input:not([type=checkbox]):not([type=radio]) {
  text-align: center;
}
.form__textfield--with-suffix-icon, .form__fieldset--with-suffix-icon input:not([type=checkbox]):not([type=radio]) {
  padding-right: 35px !important;
}
.form__textfield ::-webkit-input-placeholder, .form__fieldset input:not([type=checkbox]):not([type=radio]) ::-webkit-input-placeholder {
  color: #353946;
  font-size: 18px;
  line-height: 18px;
  line-height: 1.8rem;
  font-weight: 400;
}
.form__textfield :-moz-placeholder, .form__fieldset input:not([type=checkbox]):not([type=radio]) :-moz-placeholder {
  color: #353946;
  font-size: 18px;
  line-height: 18px;
  line-height: 1.8rem;
  font-weight: 400;
}
.form__textfield ::-moz-placeholder, .form__fieldset input:not([type=checkbox]):not([type=radio]) ::-moz-placeholder {
  color: #353946;
  font-size: 18px;
  line-height: 18px;
  line-height: 1.8rem;
  font-weight: 400;
}
.form__textfield :-ms-input-placeholder, .form__fieldset input:not([type=checkbox]):not([type=radio]) :-ms-input-placeholder {
  color: #353946;
  font-size: 18px;
  line-height: 18px;
  line-height: 1.8rem;
  font-weight: 400;
}
.form__textfield.error, .form__fieldset input.error:not([type=checkbox]):not([type=radio]) {
  border-color: #f60057;
  color: #f60057;
}
.form__textfield:-webkit-autofill, .form__fieldset input:-webkit-autofill:not([type=checkbox]):not([type=radio]), .form__textfield:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #18191d inset;
  -webkit-text-fill-color: #FFFFFF;
}
.form__select {
  margin: 0;
  padding: 0 10px;
  width: 100%;
  height: 50px;
  border: 1px solid #383d44;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #717171;
  font-size: 16px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.form__select:focus {
  border-color: #70c157;
}
.form__select.error {
  border-color: #f60057;
}
.form__textarea, .form__fieldset textarea {
  width: 100%;
  border: none;
  border-bottom: 2px solid #383d44;
  background-color: transparent;
  font-size: 14px;
  color: #FFFFFF;
  resize: vertical;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.form__textarea:focus, .form__fieldset textarea:focus {
  border-color: #70c157;
}
.form__textarea ::-webkit-input-placeholder, .form__fieldset textarea ::-webkit-input-placeholder {
  color: #353946;
  font-size: 18px;
  line-height: 22px;
  line-height: 2.2rem;
}
.form__textarea :-moz-placeholder, .form__fieldset textarea :-moz-placeholder {
  color: #353946;
  font-size: 18px;
  line-height: 22px;
  line-height: 2.2rem;
}
.form__textarea ::-moz-placeholder, .form__fieldset textarea ::-moz-placeholder {
  color: #353946;
  font-size: 18px;
  line-height: 22px;
  line-height: 2.2rem;
}
.form__textarea :-ms-input-placeholder, .form__fieldset textarea :-ms-input-placeholder {
  color: #353946;
  font-size: 18px;
  line-height: 22px;
  line-height: 2.2rem;
}
.form__textarea.error, .form__fieldset textarea.error {
  border-color: #f60057;
}

#regContainer {
  height: 100%;
}
#regContainer > div {
  height: 100%;
}

#regContent {
  padding-top: 10vh;
}

#regAdditional {
  background-color: red;
}

.wt-form input.form-control[type=text] {
  background: none;
  border: 0;
  padding: 0;
  color: #ffffff;
  caret-color: #12aaeb;
}
.wt-form input.form-control[type=text]:focus {
  outline: none;
  box-shadow: none;
}
.wt-form input.form-control[type=text]:-webkit-autofill, .wt-form input.form-control[type=text]:-webkit-autofill:hover, .wt-form input.form-control[type=text]:-webkit-autofill:focus {
  background-color: transparent;
  -webkit-box-shadow: 0 0 0px 1000px #18191d inset;
  -webkit-text-fill-color: #ffffff;
  color: #ffffff;
}
.wt-form .form-group {
  padding-left: 60px;
  margin-right: 40px;
  border-bottom: 1px solid #2a2b31;
}
.wt-form .form-group label {
  margin-bottom: 0;
}
.wt-form .form-group:focus-within > label {
  color: #12aaeb;
}
.wt-form .form-group.name {
  background: url(~/src/old/icons/settings.svg) no-repeat 20px center;
}
.wt-form .form-actions {
  text-align: center;
}

span.error {
  color: #f60057;
}

.login {
  overflow-x: hidden;
}
.login__wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-align-content: center;
  -moz-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
}
.login__form-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-flex-flow: column;
  -moz-flex-flow: column;
  -ms-flex-flow: column;
  flex-flow: column;
  width: 100%;
  height: 80vh;
  box-sizing: border-box;
}
@media screen and (min-width: 980px) {
  .login__form-content {
    width: 50%;
    height: 100%;
  }
}
@media screen and (max-width: 980px) {
  .login__form-content {
    height: auto;
  }
}
.login__button_separator_label {
  margin: 12px;
  display: block;
}
.login__bg {
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: border-box;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 2;
}
@media screen and (max-width: 979px) {
  .login__bg {
    background-image: none !important;
    max-width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    height: min-content;
    margin-top: 40px;
  }
}
@media screen and (max-width: 479px) {
  .login__bg {
    margin-top: 20px;
  }
}
@media screen and (min-width: 980px) {
  .login__bg {
    width: 50%;
    height: 100%;
  }
}
.login__bg .wrapper {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
@media screen and (min-width: 980px) {
  .login__bg .wrapper {
    height: 100%;
  }
}
.login__bg .wrapper .language {
  width: 100%;
}
@media screen and (max-width: 600px) {
  .login__bg .wrapper .language {
    display: none;
  }
}
.login__bg .wrapper .btnholder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .login__bg .wrapper .btnholder {
    width: 324px;
    margin-bottom: 70px;
    flex-direction: column;
  }
}
@media screen and (min-width: 768px) and (min-width: 768px) {
  .login__bg .wrapper .btnholder .button--demo {
    margin-bottom: 0.5rem;
  }
}
@media screen and (max-width: 767px) {
  .login__bg .wrapper .btnholder .open-account-button {
    width: 100%;
  }
  .login__bg .wrapper .btnholder .button:first-child {
    margin-right: 0.5rem;
  }
}
.login__form-holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  width: 100%;
  height: min-content;
}
.login__logoholder {
  position: relative;
}
.login__logoholder:before {
  background-image: url(~/src/old/images/login/logo-decoration.png);
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
  width: 500px;
  height: 160px;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 70px;
}
.login__inputs {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .login__inputs {
    max-width: 335px;
  }
}
.login__logo {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  z-index: 3;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 50px;
}
.login__circle {
  width: 220px;
  height: 120px;
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 1024px) {
  .login__circle {
    margin-bottom: 45px;
  }
}
.login__circle:before {
  width: 220px;
  height: 160px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  content: "";
  background: rgba(255, 255, 255, 0.35);
  filter: blur(80px);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .login__circle:before {
    height: 80px;
  }
}
@media screen and (max-width: 1024px) {
  .login__circle:after {
    content: "Wintrado";
    display: block;
    position: absolute;
    bottom: -3px;
    text-align: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 30px;
    letter-spacing: 2px;
    display: none;
  }
}
.login__title h1 {
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
  margin: 0 0 30px 0;
}
@media screen and (max-width: 360px) {
  .login__title {
    display: none;
  }
}
.login__info {
  text-align: center;
}
.login__info p {
  font-size: 14px;
  line-height: 21px;
  color: #ffffff; /* The allback */
  color: rgba(255, 255, 255, 0.6);
  max-width: 256px;
  margin: 0 auto 25px auto;
}
@media screen and (max-width: 479px) {
  .login__info {
    display: none;
  }
}
.login__form-holder {
  text-align: center;
}
@media screen and (min-width: 768px) {
  .login__form {
    min-width: 324px;
  }
}
.login__submit {
  margin: 20px 0;
  width: 100%;
}
.login__remember {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
@media screen and (max-width: 1023px) {
  .login__remember {
    padding: 0 15px;
  }
}
@media screen and (max-width: 479px) {
  .login__remember {
    padding: 0 2px;
  }
}
.login__remember .info-remember {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}
.login__links {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
@media screen and (max-width: 1023px) {
  .login__links {
    padding: 0 15px;
  }
}
@media screen and (max-width: 479px) {
  .login__links {
    padding: 0 2px;
  }
}
.login__forgot, .login__help {
  font-size: 14px;
  cursor: pointer;
  margin-top: 1rem;
}
@media screen and (max-width: 479px) {
  .login__forgot, .login__help {
    margin-top: 0.2rem;
  }
}
.login__forgot a, .login__help a {
  color: #ffffff;
}
.login__language-mobile {
  position: absolute;
  top: 0;
  right: 20px;
  z-index: 999;
}
@media screen and (min-width: 980px) {
  .login__language-mobile {
    display: none;
  }
}
@media screen and (max-height: 480px) {
  .login__language-mobile {
    top: -20px;
    right: 5px;
  }
}

.slide-exit-active, .slide-exit-done {
  opacity: 0;
  transform: translateY(40px);
  transition: all 350ms ease-out;
}

.slide-right-enter {
  opacity: 0;
  transform: translateX(-40px);
  will-change: transform;
}

.slide-right-enter-active, .slide-right-enter-done {
  opacity: 1;
  transform: translateX(0);
  transition: all 350ms ease-out;
}

.slide-right-exit {
  opacity: 1;
  transform: translateX(0);
  will-change: transform;
}

.slide-right-exit-active, .slide-right-exit-done {
  opacity: 0;
  transition: all 350ms ease-out;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active, .fade-enter-done {
  opacity: 1;
  transition: opacity 550ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active, .fade-exit-done {
  opacity: 0;
  transition: opacity 550ms ease-in;
}

.page-slide-enter {
  position: absolute;
  transform: translateX(100%);
  will-change: transform;
}

.page-slide-enter-active, .page-slide-enter-done {
  transform: translateX(0);
  transition: all 450ms ease-in-out;
}

.page-slide-exit {
  transform: translateX(0);
  will-change: transform;
}

.page-slide-exit-active, .page-slide-exit-done {
  transform: translateX(100%);
  transition: all 450ms ease-in-out;
}

.reverse-page-slide-enter {
  position: absolute;
  transform: translateX(-100%);
  will-change: transform;
}

.reverse-page-slide-enter-active, .reverse-page-slide-enter-done {
  transform: translateX(0);
  transition: all 450ms ease-in-out;
}

.reverse-page-slide-exit {
  transform: translateX(0);
  will-change: transform;
}

.reverse-page-slide-exit-active, .reverse-page-slide-exit-done {
  transform: translateX(-100%);
  transition: all 450ms ease-in-out;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
:root {
  /* Colors */
  --background-black: #1A1B1F;
  --border-gray: #3D3D3D;
  --dark-gray: #2E303B;
  --light-gray: #353946;
  --lighter-gray: #2B2E38;
  --lightest-gray: #84899C;
  --faded-white: #6C6C6C;
  --blue: #12AAEB;
  --faded-blue: #21232B;
  --light-blue: #44BBFE;
  --dark-blue: #003C56;
  --dark-red: #E10000;
  --darker-red: #411417;
  --border-white: #32343D;
  --light-green: #68FD44;
  --yellow: #FFB200;
  --orange: #E3A300;
  --loyalty-bronze: #E36629;
  --loyalty-silver: #BCB8B7;
  --loyalty-gold: #F8A300;
  --loyalty-platinum: #EEEEEE;
  --loyalty-diamond: #A3F4FF;
  --buy-color: #55D636;
  --sell-color: #CD0000;
  /* Sizes */
  --header-height: 88px;
  --header-item-height: 60px;
  --mobile-header-height: 56px;
  --mobile-header-item-height: 40px;
  /* Icon sizes */
  --icon-size-m: 24px;
  /* Gradients */
  --gray-gradient: linear-gradient(180deg, #2E303B 0%, #21232B 100%);
  --blue-gradient: linear-gradient(90deg, #44BBFE 0%, #1E78FE 100%);
  --red-gradient: linear-gradient(90deg, #E10000 0%, #AA0303 100%);
  --green-gradient: linear-gradient(90deg, #68FD44 -43.21%, #46AE2C 59.57%);
  /* Spacings */
  --spacer-xxs: 4px;
  --spacer-xs: 8px;
  --spacer-s: 12px;
  --spacer-m: 16px;
  --spacer-l: 24px;
  --spacer-xl: 32px;
  /* Border radius */
  --radius-m: 8px;
  /* Typography */
  --font-prim: "Plus Jakarta Sans", sans-serif;
  --text-color: white;
  --weight-thin: 100;
  --weight-extra-light: 200;
  --weight-light: 300;
  --weight-normal: 400;
  --weight-medium: 500;
  --weight-semi-bold: 600;
  --weight-bold: 700;
  --weight-extra-bold: 800;
  --weight-heavy: 900;
  --heading-xl: 42px;
  --heading-large: 30px;
  --heading: 24px;
  --heading-s: 20px;
  --title: 18px;
  --body: 16px;
  --label: 14px;
  --caption: 12px;
  --caption-small: 10px;
  --body-line-height: 24px;
  --heading-line-height: 32px;
  --label-line-height: 24px;
  --caption-line-height: 16px;
  /* Breakpoints */
  --xs: 479px;
  --sm: 480px;
  --md-sm: 600px;
  --md: 768px;
  --lg: 980px;
  --desktop: 1024px;
  --xlg: 1200px;
  --xxlg: 1366px;
  --xxxlg: 1920px;
  /* Z-indexes */
  --zindex-dropdown: 1000;
  --zindex-sticky: 1020;
  --zindex-fixed: 1030;
  --zindex-modal-backdrop: 1040;
  --zindex-modal: 1050;
  --zindex-popover: 1060;
  --zindex-tooltip: 1070;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: #6c6c6c;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  margin-left: -4px;
  position: absolute;
  width: 0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border-bottom-color: #2b2e38;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color: #1a1b1f;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  top: 0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #2b2e38;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  bottom: 0;
  margin-bottom: -8px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  border-bottom: none;
  border-top-color: #fff;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  bottom: 0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  bottom: -1px;
  border-top-color: #2b2e38;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}

.react-datepicker {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: white;
  border: 1px solid #2b2e38;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}
.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}
.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}
.react-datepicker-popper[data-placement^=bottom] {
  padding-top: 10px;
}
.react-datepicker-popper[data-placement=bottom-end] .react-datepicker__triangle, .react-datepicker-popper[data-placement=top-end] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}
.react-datepicker-popper[data-placement^=top] {
  padding-bottom: 10px;
}
.react-datepicker-popper[data-placement^=right] {
  padding-left: 8px;
}
.react-datepicker-popper[data-placement^=right] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}
.react-datepicker-popper[data-placement^=left] {
  padding-right: 8px;
}
.react-datepicker-popper[data-placement^=left] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #1a1b1f;
  border-bottom: 1px solid #2b2e38;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}
.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}
.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 15px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: white;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
}
.react-datepicker__navigation--previous {
  left: 2px;
}
.react-datepicker__navigation--next {
  right: 2px;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}
.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__navigation--years-previous {
  top: 4px;
}
.react-datepicker__navigation--years-upcoming {
  top: -4px;
}
.react-datepicker__navigation:hover *::before {
  border-color: rgb(69.75, 69.75, 69.75);
}

.react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0;
}
.react-datepicker__navigation-icon--next {
  left: -2px;
}
.react-datepicker__navigation-icon--next::before {
  transform: rotate(45deg);
  left: -7px;
}
.react-datepicker__navigation-icon--previous {
  right: -2px;
}
.react-datepicker__navigation-icon--previous::before {
  transform: rotate(225deg);
  right: -7px;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 180px;
}
.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}
.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time] {
  -moz-appearance: textfield;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #2b2e38;
  width: 85px;
}
.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -87px;
  top: 0;
}
.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + 1.7rem / 2);
  overflow-y: scroll;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  box-sizing: content-box;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #1a1b1f;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #12aaeb;
  color: white;
  font-weight: bold;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #12aaeb;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #6c6c6c;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #6c6c6c;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable:not(.react-datepicker__week-number--selected,
.react-datepicker__week-number--keyboard-selected):hover {
  border-radius: 0.3rem;
  background-color: #1a1b1f;
}
.react-datepicker__week-number--selected {
  border-radius: 0.3rem;
  background-color: #12aaeb;
  color: #fff;
}
.react-datepicker__week-number--selected:hover {
  background-color: rgb(16.185770751, 152.8656126482, 211.314229249);
}
.react-datepicker__week-number--keyboard-selected {
  border-radius: 0.3rem;
  background-color: rgb(63.6561264822, 187.418972332, 240.3438735178);
  color: #fff;
}
.react-datepicker__week-number--keyboard-selected:hover {
  background-color: rgb(16.185770751, 152.8656126482, 211.314229249);
}

.react-datepicker__day-names {
  white-space: nowrap;
  margin-bottom: -8px;
}

.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: white;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  background-color: #1a1b1f;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
}
.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: 0.3rem;
  background-color: #12aaeb;
  color: #fff;
}
.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
  background-color: rgb(16.185770751, 152.8656126482, 211.314229249);
}
.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
  color: magenta;
}
.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}
.react-datepicker__day--holidays,
.react-datepicker__month-text--holidays,
.react-datepicker__quarter-text--holidays,
.react-datepicker__year-text--holidays {
  position: relative;
  border-radius: 0.3rem;
  background-color: #ff6803;
  color: #fff;
}
.react-datepicker__day--holidays .holiday-overlay,
.react-datepicker__month-text--holidays .holiday-overlay,
.react-datepicker__quarter-text--holidays .holiday-overlay,
.react-datepicker__year-text--holidays .holiday-overlay {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 4px;
  border-radius: 4px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease-in-out;
}
.react-datepicker__day--holidays:hover,
.react-datepicker__month-text--holidays:hover,
.react-datepicker__quarter-text--holidays:hover,
.react-datepicker__year-text--holidays:hover {
  background-color: rgb(207, 82.9642857143, 0);
}
.react-datepicker__day--holidays:hover .holiday-overlay,
.react-datepicker__month-text--holidays:hover .holiday-overlay,
.react-datepicker__quarter-text--holidays:hover .holiday-overlay,
.react-datepicker__year-text--holidays:hover .holiday-overlay {
  visibility: visible;
  opacity: 1;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #12aaeb;
  color: #fff;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: rgb(16.185770751, 152.8656126482, 211.314229249);
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: rgb(229.4565217391, 245.9782608696, 253.0434782609);
  color: rgb(0, 0, 0);
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: rgb(16.185770751, 152.8656126482, 211.314229249);
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
  background-color: rgba(18, 170, 235, 0.5);
}
.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range), .react-datepicker__year--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range) {
  background-color: #1a1b1f;
  color: white;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #6c6c6c;
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}
.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  padding: 0.5rem;
  box-sizing: content-box;
}

.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 25px;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
  position: relative;
}
.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: rgb(82.5, 82.5, 82.5);
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  transform: rotate(135deg);
  right: -16px;
  top: 0;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #1a1b1f;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #2b2e38;
}
.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}
.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #6c6c6c;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: rgb(82.5, 82.5, 82.5);
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: rgb(82.5, 82.5, 82.5);
}
.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #12aaeb;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}
.react-datepicker__close-icon--disabled {
  cursor: default;
}
.react-datepicker__close-icon--disabled::after {
  cursor: default;
  background-color: #6c6c6c;
}

.react-datepicker__today-button {
  background: #1a1b1f;
  border-top: 1px solid #2b2e38;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}
@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__children-container {
  width: 13.8rem;
  margin: 0.4rem;
  padding-right: 0.2rem;
  padding-left: 0.2rem;
  height: auto;
}

.react-datepicker__aria-live {
  position: absolute;
  clip-path: circle(0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  white-space: nowrap;
}

.react-datepicker__calendar-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
}

.react-datepicker {
  background: #2b2e38;
}
.react-datepicker-wrapper {
  width: 100%;
  height: 100%;
}
.react-datepicker__triangle:after, .react-datepicker__triangle:before {
  border-top-color: #2b2e38 !important;
}
.react-datepicker__day--outside-month {
  opacity: 0.5;
}
.react-datepicker__input-container {
  height: 100%;
}

/* inspired by: https://css-tricks.com/responsive-data-tables/ */
.responsiveTable {
  width: 100%;
}

.responsiveTable td .tdBefore {
  display: none;
}

@media screen and (max-width: 1023px) {
  /*
    Force table elements to not behave like tables anymore
    Hide table headers (but not display: none;, for accessibility)
  */
  .responsiveTable table,
  .responsiveTable thead,
  .responsiveTable tbody,
  .responsiveTable th,
  .responsiveTable td,
  .responsiveTable tr {
    display: block;
  }
  .responsiveTable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
    border-bottom: 2px solid #333;
  }
  .responsiveTable tbody tr {
    padding: 0.25em;
  }
  .responsiveTable td.pivoted {
    /* Behave like a "row" */
    border: none !important;
    position: relative;
    padding-left: calc(50% + 10px) !important;
    text-align: left !important;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    font-size: 14px;
    font-weight: 300;
  }
  .responsiveTable td .tdBefore {
    /* Now like a table header */
    position: absolute;
    display: block;
    /* Top/left values mimic padding */
    left: 1rem;
    width: calc(50% - 20px);
    white-space: pre-wrap;
    overflow-wrap: break-word;
    text-align: left !important;
    font-size: 12px;
    color: #A7A8A8;
  }
}
.errorMessage {
  padding: 0;
  position: absolute;
  left: 0;
  right: 0;
  border: 0;
  opacity: 0;
  z-index: 1000;
  text-align: center;
  max-width: 90%;
  margin: auto;
  pointer-events: none;
}
.errorMessage.enter {
  opacity: 0;
  -webkit-transform: translateY(-40px);
  -moz-transform: translateY(-40px);
  -o-transform: translateY(-40px);
  -ms-transform: translateY(-40px);
  transform: translateY(-40px);
}
.errorMessage.enter-active, .errorMessage.enter-done {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  transform: translateY(0);
  transition: all 350ms ease-out;
}
.errorMessage.exit {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.errorMessage.exit-active, .errorMessage.exit-done {
  opacity: 0;
  -webkit-transform: translateY(-40px);
  -moz-transform: translateY(-40px);
  -o-transform: translateY(-40px);
  -ms-transform: translateY(-40px);
  transform: translateY(-40px);
  transition: all 350ms ease-out;
}
.errorMessage .alert-warning {
  display: inline-block;
  padding: 0;
  background-color: #CD0000;
  border-radius: 0 0 12px 12px;
  border: none;
}
.errorMessage .alert-wrapper {
  padding: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: none;
  flex-wrap: nowrap;
}
.errorMessage.display {
  opacity: 1;
}
.errorMessage .row {
  padding: 5px;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: none;
  flex-wrap: nowrap;
}
.errorMessage .alert-content {
  position: relative;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .errorMessage .alert-content {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
}
.errorMessage .icon-container {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  min-width: 60px;
}
@media screen and (min-width: 768px) {
  .errorMessage .icon-container {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
}
.errorMessage .icon-container .icon {
  fill: white;
  margin: 5px 0 0 5px;
  width: 52px;
  height: 52px;
}
.errorMessage h2 {
  color: white;
  font-size: 18px;
  font-weight: 700;
}
.errorMessage span, .errorMessage i {
  color: white;
  font-weight: 500;
}
.errorMessage .progress {
  border-radius: 0;
  background: transparent;
}
.errorMessage .progress .progress-bar {
  height: 4px;
  line-height: 4px;
  vertical-align: bottom;
  background: linear-gradient(141deg, #f60057 0%, #f60057 51%, #f60057 75%);
}
@media screen and (max-width: 767px) {
  .errorMessage .alert {
    min-width: 140px;
  }
  .errorMessage .row {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.bubble-spinner {
  position: absolute;
  top: 40%;
  left: 40%;
}

.ball-clip-rotate div {
  width: 100px;
  height: 100px;
}

.card-block {
  padding: 0;
}

.odd {
  background-color: rgb(62.5, 62.5, 62.5);
  padding: 10px 0 0 0;
}

.even {
  padding: 10px 0 0 0;
}

Ž .install-button-wrapper {
  padding-top: 5px;
}
Ž .install-button-wrapper button {
  padding: 3px 45px;
  outline: none;
  border: none;
}

#iosInstallNotification {
  position: absolute;
  bottom: 50px;
  width: 100%;
  text-align: center;
  display: block;
  z-index: 99999;
}
#iosInstallNotification div.holder {
  display: inline-block;
  background-color: #353946;
  width: 90%;
  padding: 30px 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid transparent;
  position: relative;
  box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.5);
}
#iosInstallNotification div.holder:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -19px;
  width: 0;
  height: 0;
  border-top: solid 19px #353946;
  border-left: solid 19px transparent;
  border-right: solid 19px transparent;
}
#iosInstallNotification div.holder .title {
  color: white;
  font-weight: 700;
  margin: 0 0 10px 0;
  font-size: 24px;
  line-height: 24px;
}
#iosInstallNotification div.holder .description {
  max-width: 200px;
  color: #84899c;
  font-size: 14px;
  line-height: 21px;
  margin: 0 auto;
}
#iosInstallNotification div.holder .description .tapicon {
  width: 24px;
  height: 24px;
  background: #84899c;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #2b2e38;
  position: relative;
  display: inline-block;
  margin: 5px 5px -8px 5px;
}
#iosInstallNotification div.holder .description .tapicon img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#iosInstallNotification div.holder .description .homescreen strong {
  color: white;
  font-weight: 700;
  word-break: break-word;
  display: block;
  margin-top: 7px;
}
#iosInstallNotification div.holder .close-popup {
  position: absolute;
  color: white;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
#iosInstallNotification div.holder .close-popup svg {
  width: 24px;
  height: 24px;
  margin-bottom: 5px;
}

#androidInstallNotification {
  position: absolute;
  top: 70px;
  bottom: 70px;
  left: 20px;
  right: 20px;
  text-align: center;
  display: block;
  color: black;
  z-index: 99999;
}
#androidInstallNotification > div {
  background-color: #fff;
  background-image: url(~/src/old/images/install-popup-background-bubbles.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  padding: 3rem 1rem;
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#androidInstallNotification > div .cta-header {
  flex: 1;
  display: flex;
  flex-direction: column;
}
#androidInstallNotification > div h2 {
  margin: 0 15%;
}
#androidInstallNotification > div h2, #androidInstallNotification > div strong {
  color: #18191D;
}
#androidInstallNotification > div p {
  margin: 2rem 10% 0;
  color: rgba(24, 25, 29, 0.5);
}
#androidInstallNotification > div .image-wrapper {
  flex: 1;
  padding: 0 5%;
}
#androidInstallNotification > div .image-wrapper img {
  width: 100%;
  filter: drop-shadow(10px 10px 30px rgba(0, 0, 0, 0.15));
}
#androidInstallNotification > div .install-button-wrapper {
  flex: 1;
  display: flex;
  align-items: flex-end;
}
#androidInstallNotification .close-popup i {
  font-size: 1.5625rem;
}
#androidInstallNotification strong {
  font-weight: 700;
}

#position-open-price {
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
#position-open-price span {
  font-size: 12px;
  color: white;
}
@media screen and (max-width: 600px) {
  #position-open-price span {
    font-size: 12px;
  }
}
@media screen and (max-width: 1023px) {
  #position-open-price img {
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }
}
@media screen and (max-width: 600px) {
  #position-open-price img {
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
}

.offline-notice {
  position: fixed;
  top: 0;
  left: 50px;
  right: 50px;
  z-index: 100;
  background-color: #303640;
  padding: 10px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  will-change: transform, opacity;
  opacity: 0;
  transform-origin: top;
  transition: all 0.3s ease-in-out;
  transform: scaleY(0);
}
.offline-notice.active {
  opacity: 1;
  transform: none;
}
.offline-notice span {
  display: block;
  width: 100%;
  color: white;
  text-align: center;
}

#tutorial_modal .info-tutorial {
  padding: 1rem;
  text-align: center;
}
#tutorial_modal .info-tutorial p {
  font-size: 14px;
}
@media screen and (min-width: 768px) {
  #tutorial_modal .info-tutorial p {
    font-size: 18px;
  }
}
@media screen and (max-width: 768px) {
  #tutorial_modal .modal-dialog {
    max-width: 100%;
    margin: 0;
  }
}
@media screen and (max-width: 768px) {
  #tutorial_modal .modal-dialog .modal-content {
    height: 100%;
    padding: 0;
  }
}
@media screen and (max-height: 600px) {
  #tutorial_modal .modal-dialog .modal-content {
    overflow-y: auto;
    overflow-x: hidden;
  }
}
@media screen and (max-width: 768px) {
  #tutorial_modal .modal-dialog .modal-content .modal_btns .button {
    padding: 15px 25px;
    font-size: 12px;
    background: transparent;
    border: 2px solid #ffffff;
    border-radius: 30px;
    width: 190px;
    max-width: 100%;
    font-weight: 600;
  }
}
@media screen and (max-width: 980px) {
  #tutorial_modal .modal-dialog .modal-content .modal_btns .button {
    font-size: 16px;
  }
}
@media only screen and (min-width: 1121px) and (max-height: 660px) {
  #tutorial_modal .video-container {
    width: 100vh;
  }
}
@media only screen and (min-width: 1121px) and (max-height: 480px) {
  #tutorial_modal .video-container {
    width: 70vh;
  }
}

#popup-notifications {
  position: absolute;
  right: 0;
  z-index: 9999;
}
@media screen and (max-width: 600px) {
  #popup-notifications {
    left: 0;
  }
}

.popup-notification {
  margin: 0.5rem 1.5rem;
}
@media screen and (max-width: 600px) {
  .popup-notification {
    margin: 1rem;
  }
}
.popup-notification.enter {
  opacity: 0.01;
  transform: translate(-40px, 0);
}
.popup-notification.enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 350ms ease-out;
}
.popup-notification.exit {
  opacity: 1;
  transform: translate(0, 0);
}
.popup-notification.exit-active {
  opacity: 0.01;
  transform: translate(40px, 0);
  transition: all 350ms ease-out;
}
.popup-notification__container {
  background: #fff;
  color: #2a2e31;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}
@media screen and (min-width: 600px) {
  .popup-notification__container {
    min-width: 347px;
  }
}
.popup-notification__container .holder {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  background: #E4E6E9;
  max-width: 70px;
}
.popup-notification__container .holder .icon {
  width: 48px;
  height: 25px;
}
.popup-notification__container .content {
  -webkit-box-flex: 3;
  -webkit-flex: 3;
  -moz-box-flex: 3;
  -moz-flex: 3;
  -ms-flex: 3;
  flex: 3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 20px;
}
.popup-notification__container .content .tip {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  -moz-align-items: baseline;
  align-items: baseline;
}
.popup-notification__container .content .tip .label-container {
  padding-right: 10px;
}
.popup-notification__container .content .tip .label-container span {
  font-weight: 700;
  font-size: 14px;
}
.popup-notification__container .content .tip .description {
  font-size: 14px;
  line-height: 21px;
  max-width: 170px;
}
.popup-notification__container .content .buttons-container {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
@media screen and (min-width: 600px) {
  .popup-notification__container .content .buttons-container {
    flex-direction: row;
    justify-content: flex-end;
  }
}
.popup-notification__container .content .buttons-container .button {
  line-height: 18px;
  padding: 9px 20px;
  max-height: 38px;
}
@media screen and (max-width: 599px) {
  .popup-notification__container .content .buttons-container .button {
    width: 100%;
  }
}
.popup-notification__container .content .buttons-container .button:not(:last-child) {
  margin-right: 0.5rem;
}
@media screen and (max-width: 599px) {
  .popup-notification__container .content .buttons-container .button:first-child {
    margin-bottom: 10px;
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fullscreen-iframe-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background-color: #18191d;
}
.fullscreen-iframe-container iframe {
  width: 100%;
  height: 100%;
}
.fullscreen-iframe-container .close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: white;
  color: black;
  font-size: 24px;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 99999999;
}

@media screen and (max-width: 1024px) {
  .popup-menu {
    width: 100%;
  }
}
.popup-menu a {
  display: inline-block;
  position: relative;
  color: #ffffff;
  font-weight: 200;
}
@media screen and (max-width: 1024px) {
  .popup-menu a {
    display: block;
    font-weight: 600;
  }
}
.popup-menu a:hover {
  color: white;
}
.popup-menu a span {
  position: relative;
  padding: 8px 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: block;
  z-index: calc(1000 + 1);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
@media screen and (min-width: 1024px) {
  .popup-menu a span {
    min-width: 140px;
    width: max-content;
    padding: 0 5px;
    font-size: 14px;
    height: 60px;
    display: inline-flex;
    justify-content: center;
    justify-items: center;
  }
}
@media screen and (min-width: 1400px) {
  .popup-menu a span {
    min-width: 140px;
    padding: 11px 5px;
    font-size: 16px;
  }
}
@media screen and (max-width: 1024px) {
  .popup-menu a span {
    background-image: none;
    background-size: 0;
    padding: 6px;
    text-align: center;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    font-size: 18px;
  }
}
@media screen and (max-width: 600px) {
  .popup-menu a span {
    font-size: 14px;
  }
}
@media screen and (max-width: 479px) {
  .popup-menu a span {
    padding: 2px 5px;
  }
}
.popup-menu a span p {
  margin-bottom: 0;
  font-size: 10px;
  color: #ffffff;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  -ms-opacity: 0.5;
  -o-opacity: 0.5;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
@media screen and (max-width: 1023px) {
  .popup-menu a span strong {
    display: block;
    color: #ffffff;
    font-weight: 600;
  }
}
.popup-menu a strong {
  font-weight: 700;
  font-size: 14px;
}
@media screen and (min-width: 1024px) {
  .popup-menu a strong {
    font-size: 18px;
  }
}
.popup-menu a > ul {
  z-index: 1000;
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.35);
  list-style-type: none;
  display: none;
  position: absolute;
  bottom: 25px;
  left: 0;
  width: 100%;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -ms-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
  padding-bottom: 20px;
}
@media screen and (max-width: 1024px) {
  .popup-menu a > ul {
    bottom: 100%;
    padding: 0;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    -ms-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
    background: transparent;
    overflow: hidden;
    margin-bottom: 0;
  }
}
.popup-menu a > ul li {
  font-size: 16px;
  text-align: center;
  width: 100%;
  padding: 10px;
  font-weight: 700;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  .popup-menu a > ul li {
    font-weight: 400;
    color: white;
    font-size: 16px;
    background: #141618;
    padding: 10px;
    margin-bottom: 4px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
  }
}
@media screen and (max-width: 600px) {
  .popup-menu a > ul li {
    font-size: 12px;
  }
}
.popup-menu a > ul li img.mobile-only {
  display: none;
}
.popup-menu a > ul li img.mobile-only:first-child {
  margin-right: auto;
}
.popup-menu a > ul li img.mobile-only:last-child {
  margin-left: auto;
}
@media screen and (max-width: 1024px) {
  .popup-menu a > ul li img.mobile-only {
    display: block;
  }
}
@media screen and (max-width: 1024px) {
  .popup-menu a > ul li:not(.disabled):hover {
    background: #2a3033;
  }
}
@media screen and (min-width: 1024px) {
  .popup-menu a > ul li:not(.disabled):hover {
    background: rgba(0, 0, 0, 0.2);
  }
}
.popup-menu a > ul li.disabled {
  opacity: 0.4;
  cursor: default;
}
.popup-menu a:hover {
  text-decoration: none;
}
@media screen and (max-width: 1024px) {
  .popup-menu.period {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .popup-menu.period a {
    display: block;
    font-weight: 600;
  }
}
.popup-menu.profitloss {
  padding-right: 5px;
}
@media screen and (max-width: 1024px) {
  .popup-menu.profitloss {
    padding-right: 0;
  }
}
.popup-menu.profitloss .coins-wrapper {
  display: inline-flex;
  margin-left: 0.5rem;
  font-size: 12px;
}
.popup-menu.profitloss .coins-wrapper .coins-label {
  margin-left: 0.1rem;
}
.popup-menu.invest li img {
  width: 25px;
}
.popup-menu.invest li.disabled {
  opacity: 0.5;
}
@media screen and (max-width: 1024px) {
  .popup-menu ul.m-show {
    z-index: 1050;
  }
}
@media screen and (min-width: 1024px) {
  .popup-menu:hover ul {
    display: block !important;
  }
}

@media screen and (max-width: 1024px) {
  .popup-menu-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    z-index: 1040;
  }
}

.debug-popup-overlay {
  position: absolute;
  background-color: #18191d;
  display: none;
}

.debug-popup {
  position: absolute;
  top: 5px;
  left: 85px;
  z-index: 9999999;
}
.debug-popup .button {
  display: flex;
  flex-direction: column;
  padding: 6px 12px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #2a2b31;
  border-radius: 6px;
}
.debug-popup .button span {
  margin-left: 6px;
}
.debug-popup .button svg {
  width: 22px;
  height: 22px;
  fill: #CD0000;
}

@media (max-width: 1120px) {
  .debug-popup-overlay {
    top: 0;
    left: 49px;
    right: 49px;
    height: 39px;
    display: block;
  }
  .debug-popup {
    top: 5px;
    left: 49px;
    right: 49px;
    height: 39px;
  }
  .debug-popup .button {
    margin: 0 auto;
    width: 120px;
    height: 39px;
    flex-direction: row;
  }
}
@keyframes flashing-warning {
  0%, 49% {
    border-color: #2a2e31;
  }
  50%, 100% {
    border-color: #f5004d;
  }
}
.stat__holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
.stat__holder a {
  text-decoration: none;
}
.stat__holder a:hover {
  text-decoration: none;
}
@media screen and (max-width: 1023px) {
  .stat__holder {
    margin-left: auto;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
  }
}
@media screen and (min-width: 600px) and (max-width: 1023px) {
  .stat__holder {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
  }
}
@media screen and (max-width: 1023px) {
  .stat__holder a {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -moz-box-flex: 1;
    -moz-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
}
@media screen and (min-width: 600px) and (max-width: 1023px) {
  .stat__holder a {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
}
@media screen and (max-width: 599px) {
  .stat__holder a {
    flex: 0 1 50%;
  }
}
.stat__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0.5rem 0.8rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  border: 1.5px solid #2a2e31;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
  background: #1d2024;
  height: 60px;
}
@media screen and (max-width: 1023px) {
  .stat__item {
    padding: 2px 5px;
    max-height: 49px;
    height: unset;
  }
}
@media screen and (max-width: 404px) {
  .stat__item {
    padding: 2px 7px;
  }
}
.stat__item--with-dropdown {
  position: relative;
}
.stat__item--in-dropdown {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
}
.stat__item--in-dropdown:not(:last-child) {
  border-radius: 0;
  border-bottom: none;
}
.stat__item--warning {
  animation: flashing-warning 1s infinite;
}
.stat__item--warning .stat__item--in-dropdown {
  animation: flashing-warning 1s infinite;
}
.stat__item.balance {
  min-width: 130px;
}
@media screen and (max-width: 1023px) {
  .stat__item.balance {
    min-width: min-content;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -moz-box-flex: 1;
    -moz-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
}
@media screen and (min-width: 600px) and (max-width: 1023px) {
  .stat__item.balance {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
}
@media screen and (max-width: 599px) {
  .stat__item.balance {
    flex: 0 1 50%;
  }
}
.stat__item.balance .prepend img {
  max-width: 12px;
}
.stat__item.stars {
  margin-right: 10px;
}
.stat__item.coins {
  margin-left: 10px;
}
.stat__item .prepend {
  min-width: 10px;
  margin-right: 0.8rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
@media screen and (max-width: 1023px) {
  .stat__item .prepend {
    margin-right: 5px;
    min-width: 18px;
  }
}
@media screen and (max-width: 330px) {
  .stat__item .prepend {
    display: none;
  }
}
@media screen and (max-width: 404px) {
  .stat__item .prepend img {
    width: 20px;
  }
}
.stat__item .labelDataWrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
.stat__item .labelDataWrapper .labelTinyAppendWrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end;
}
.stat__item .labelDataWrapper .dataWrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.stat__item span.label {
  font-size: 10px;
  color: #A6A6A8;
  text-align: start;
}
.stat__item span.data {
  font-size: 16px;
  font-weight: 700;
  color: white;
  text-align: start;
}
.stat__item .append {
  margin-left: 0.8rem;
}
.stat__dropdown-list {
  position: absolute;
  top: 95%;
  left: -1.5px;
  right: -1.5px;
  z-index: 1000;
  overflow: hidden;
  max-height: 0;
}

body.alert-shown {
  overflow: hidden;
}

#popup-alert-wrapper .popup-alert {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}
#popup-alert-wrapper .popup-alert .alert-wrapper {
  max-width: 600px;
}
#popup-alert-wrapper .popup-alert .button-wrapper {
  display: flex;
}
#popup-alert-wrapper .popup-alert .button-wrapper button:not(:first-child) {
  margin-left: 1rem;
}
#popup-alert-wrapper .popup-alert.enter {
  opacity: 0;
}
#popup-alert-wrapper .popup-alert.enter-active {
  opacity: 1;
  transition: opacity 600ms ease;
}
#popup-alert-wrapper .popup-alert.enter-done {
  opacity: 1;
}
#popup-alert-wrapper .popup-alert.enter-done .alert-wrapper {
  opacity: 1;
}
#popup-alert-wrapper .popup-alert.exit-active {
  opacity: 0;
  transition: opacity 600ms ease;
}
#popup-alert-wrapper .popup-alert.exit-active .alert-wrapper {
  opacity: 0;
}
#popup-alert-wrapper .popup-alert .alert-wrapper {
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#popup-alert-wrapper .popup-alert button.close {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1rem;
  font-size: 2rem;
  background: transparent;
  border: none;
  outline: none;
}
#popup-alert-wrapper .popup-alert .icon-container.success, #popup-alert-wrapper .popup-alert .icon-container.error {
  border-radius: 100%;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5), 0px 0px 40px rgba(0, 0, 0, 0.5);
}
#popup-alert-wrapper .popup-alert .icon-container.success {
  background: linear-gradient(157.57deg, #70C157 8.66%, #55D636 91.51%), #55D636;
}
#popup-alert-wrapper .popup-alert .icon-container.error {
  background: linear-gradient(103.09deg, #CD0000 21.7%, #BB173E 59.43%);
}
#popup-alert-wrapper .popup-alert .title {
  font-size: 30px;
  text-align: center;
  margin: 2rem 0;
}
#popup-alert-wrapper .popup-alert .subtitle {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 18px;
}
#popup-alert-wrapper .popup-alert button {
  padding: 0.5rem 1.5rem;
  color: white;
  font-weight: 700;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  outline: none;
}
#popup-alert-wrapper .popup-alert button.success {
  background: linear-gradient(128.52deg, #70C157 21.7%, #55D636 59.43%);
}
#popup-alert-wrapper .popup-alert button.error {
  background: linear-gradient(103.09deg, #CD0000 21.7%, #BB173E 59.43%);
}

.main_content {
  flex: 1;
}

.page-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* Switch button */
}
.page-container .title-bar {
  display: flex;
  justify-content: space-between;
}
.page-container .title-bar .back-button-wrapper {
  flex: 1;
}
.page-container .title-bar .title-subtitle-wrapper {
  flex: 1;
}
.page-container .title-bar .title-append-wrapper {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 1023px) {
  .page-container--with-mobile-action-item .content-wrapper {
    padding-bottom: 100px;
  }
}
.page-container--embedded-iframe > .container {
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.page-container > .container {
  padding-right: 30px;
  padding-left: 30px;
  max-width: 100%;
}
@media screen and (max-width: 1023px) {
  .page-container > .container {
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }
}
.page-container .mobile-action-item {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #18191d;
  padding: 20px 30px 30px;
}
.page-container .mobile-action-item:before {
  position: absolute;
  content: "";
  height: 50px;
  top: -50px;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(24, 25, 29, 0) 0%, #18191D 100%);
}
.page-container .content-wrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.page-container .loader-container {
  min-height: 400px;
}
.page-container .row.spacing {
  padding-top: 30px;
}
.page-container .vr {
  border-left: 1px solid #747474;
  width: 1px;
  margin: 0 auto;
  height: 100%;
}
.page-container .btn-default.btn-on.active {
  background-color: #12aaeb;
  color: white;
}
.page-container .btn-default.btn-off.active {
  background-color: #f60057;
  color: white;
}
.page-container .btn-off,
.page-container .btn-on {
  background-color: #707070;
  padding-top: 16px;
  padding-bottom: 16px;
}
.page-container .tabs-left a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  font-weight: 200;
}
.page-container .tabs-left a i {
  float: right;
  margin-top: 7px;
}
.page-container .tabs-left a li {
  width: 100%;
  border-top: 1px solid #747474;
  opacity: 0.5;
  padding-bottom: 20px;
  display: inline-block;
}
.page-container .tabs-left a.active li {
  opacity: 1;
}
.page-container .tabs-left a.active li i {
  background-color: white;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  color: #000;
}
.page-container .tab-content {
  font-size: 18px;
  font-weight: 200;
}
.page-container .nav-tabs {
  border: none;
  display: block;
}
.page-container.trading-strategy .container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}
.page-container.trading-strategies .container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.page-container.messages .container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex: 1;
}
.page-container.messages .no-messages-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-container.messages .content-wrapper {
  min-height: 0;
}
.page-container.messages .messages-wrapper {
  width: 100%;
  overflow-y: auto;
  margin: 1rem auto 0;
  max-width: 840px;
}
@media screen and (max-width: 1023px) {
  .page-container.messages .messages-wrapper {
    flex-direction: column-reverse;
    margin: 1rem 0 0;
    max-width: 100%;
  }
}
.page-container.messages .infinite-scroll-component {
  overflow: hidden !important;
}

.page-container.message-inner .container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex: 1;
}
.page-container.message-inner .content-wrapper {
  min-height: 0;
  width: 100%;
  max-width: 840px;
  margin: 0 auto 150px;
}
@media screen and (max-width: 1023px) {
  .page-container.message-inner .content-wrapper {
    margin-bottom: 15px;
  }
}
.page-container.message-inner .message-wrapper {
  margin-top: 35px;
  background: #1d2024;
  border: 1px solid #2a2e31;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1023px) {
  .page-container.message-inner .message-wrapper {
    margin: 0;
  }
}
.page-container.message-inner .message-wrapper__content {
  overflow-y: auto;
  flex: 1;
  padding: 50px;
}
@media screen and (max-width: 1023px) {
  .page-container.message-inner .message-wrapper__content {
    padding: 20px;
  }
}
.page-container.message-inner .message-wrapper__date {
  display: block;
  color: #646d7b;
  font-size: 12px;
  margin-bottom: 1rem;
}
.page-container.message-inner .message-wrapper__title {
  font-weight: 700;
  font-size: 16px;
  flex: 1;
  min-width: 0;
  margin-bottom: 1rem;
}
.page-container.message-inner .message-wrapper__message {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: regular;
  color: #A6A6A8;
  white-space: pre-line;
}
.page-container.message-inner .message-wrapper__actions {
  background: linear-gradient(134.22deg, #2F3235 -63.52%, #1F2225 74.66%), #2a2e31;
  padding: 16px;
}
.page-container.message-inner .message-wrapper__actions .message-action {
  margin-right: 10px;
}
@media screen and (max-width: 1023px) {
  .page-container.message-inner .message-wrapper__actions {
    display: flex;
    justify-content: stretch;
    align-items: center;
  }
  .page-container.message-inner .message-wrapper__actions .message-action {
    display: block;
    text-align: center;
    flex: 1;
  }
  .page-container.message-inner .message-wrapper__actions .message-action.learn-more {
    flex: 2;
  }
  .page-container.message-inner .message-wrapper__actions .message-action .button {
    width: 100%;
  }
}
.page-container.message-inner--with-actions .message-wrapper__content {
  padding-bottom: 25px;
}

.page-container.withdraw .container, .page-container.deposit .container {
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.page-container.withdraw .container iframe, .page-container.deposit .container iframe {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ReactModal__Content {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  outline: none;
  padding: 50px 20px;
  max-width: 600px;
  width: calc(100% - 60px);
  border-radius: 20px;
  color: #ffffff;
}
.ReactModal__Content--light {
  color: #18191d;
}
@media screen and (max-width: 768px) {
  .ReactModal__Content {
    border-radius: 5px;
  }
}
.ReactModal__Content h2.title {
  line-height: 40px;
  text-align: center;
}
.ReactModal__Content p.description {
  opacity: 0.6;
  font-size: 16px;
  line-height: 1.5;
}

.modal {
  text-align: center;
  padding: 0 !important;
  position: absolute;
}
.modal:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: -4px; /* Adjusts for spacing */
}
.modal .close {
  font-size: 3.5rem;
  font-weight: 100;
  color: #ffffff;
  -webkit-text-shadow: 0 1px 0 #ffffff;
  -moz-text-shadow: 0 1px 0 #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  position: absolute;
  top: 5%;
  right: 5%;
  z-index: 2;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  -ms-transition: opacity 0.25s ease;
  -o-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}
@media screen and (min-width: 1024px) {
  .modal .close {
    top: 10%;
    right: 10%;
  }
}
.modal .close:hover {
  color: #ffffff;
}
.modal .close span {
  font-size: inherit;
  font-weight: inherit;
}
.modal .modal-dialog {
  max-width: 800px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%) !important;
  -moz-transform: translate(-50%, -50%) !important;
  -o-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}
@media screen and (max-width: 1024px) {
  .modal .modal-dialog {
    width: 100%;
  }
}
.modal .modal-dialog .modal-content {
  background-color: transparent;
  color: #FFF;
  padding: 25px;
  font-size: 24px;
  font-weight: 200;
  text-align: center;
  border: 0;
}
@media screen and (max-width: 1023px) {
  .modal .modal-dialog .modal-content {
    text-align: center;
    font-size: 18px;
  }
}
@media screen and (max-width: 480px) {
  .modal .modal-dialog .modal-content {
    font-size: 14px;
  }
}
.modal .modal-dialog .modal-content .title {
  font-size: 30px;
  color: #ffffff;
  max-width: 340px;
  line-height: 40px;
  font-weight: 700;
  text-align: center;
  margin: 60px auto 30px auto;
}
@media screen and (min-width: 1024px) {
  .modal .modal-dialog .modal-content .title {
    margin: 60px auto 30px auto;
  }
}
.modal .modal-dialog .modal-content .modal_btns {
  margin-top: 30px;
}
.modal .modal-dialog .modal-content .modal_btns .holder {
  margin: 0 auto;
  max-width: 306px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around;
}
@media screen and (min-width: 1024px) {
  .modal .modal-dialog .modal-content .modal_btns .holder {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
  }
}
.modal .modal-dialog .modal-content .modal_btns .holder .button {
  min-width: 120px;
}
@media screen and (min-width: 768px) {
  .modal .modal-dialog .modal-content .modal_btns .holder .button {
    min-width: 143px;
  }
}
.modal .modal-dialog .modal-content iframe {
  max-width: 100%;
}
.modal .modal-dialog .modal-content .video-container {
  max-width: 100%;
  width: 800px;
}
.modal .modal-dialog .modal-content .video-container video {
  max-width: 100vw;
}
.modal .modal-dialog .modal-content .video-placeholder {
  max-width: 100%;
  width: 800px;
  padding-top: 56.25%;
}
.modal--competition .modal-dialog {
  padding: 0;
  margin: 0;
}
@media screen and (min-width: 350px) {
  .modal--competition .modal-dialog {
    width: 350px;
  }
}
.modal--competition .modal-dialog .close {
  position: absolute;
  top: 20px;
  right: 20px;
  text-shadow: none;
  opacity: 1;
  color: #fff;
  font-size: 42px;
  font-weight: 100;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
@media screen and (min-width: 1024px) {
  .modal--competition .modal-dialog .close {
    top: 5px;
    right: 10px;
  }
}
.modal--competition .modal-dialog .close:hover {
  cursor: pointer;
  font-weight: 200;
}
.modal--competition .modal-dialog .modal-content {
  color: #FFF;
  text-align: center;
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  background: -o-linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  background: -ms-linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  background: -moz-linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  background: -webkit-linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  padding: 50px 40px 40px 40px;
}
.modal--competition .modal-dialog .modal-content .title {
  font-size: 18px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  margin: 0px auto 30px auto;
}
@media screen and (min-width: 479px) {
  .modal--competition .modal-dialog .modal-content .title {
    font-size: 24px;
  }
}
@media screen and (min-width: 1024px) {
  .modal--competition .modal-dialog .modal-content .title {
    margin: 0px auto 30px auto;
  }
}
.modal--competition .modal-dialog .modal-content .illustration {
  width: auto;
  margin: auto;
}
@media screen and (min-width: 479px) {
  .modal--competition .modal-dialog .modal-content .illustration {
    width: 210px;
  }
}
.modal--competition .modal-dialog .modal-content .illustration .small-labels {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  margin-top: -1.2em;
  font-weight: 700;
  color: white;
  font-size: 80%;
}
.modal--competition .modal-dialog .modal-content .illustration .small-labels span {
  width: 31.25%;
}
.modal--competition .modal-dialog .modal-content .illustration .small-labels span.hidden {
  visibility: hidden;
}
.modal--competition .modal-dialog .modal-content .illustration span.big-label {
  display: block;
  text-align: center;
  font-weight: 700;
  color: white;
  margin-top: -0.3em;
}
.modal--competition .modal-dialog .modal-content .notice {
  max-width: 270px;
  margin: 20px auto 0 auto;
}
.modal--competition .modal-dialog .modal-content .notice p {
  color: #ffffff;
  opacity: 0.6;
  font-size: 14px;
  line-height: 21px;
}
.modal--competition .modal-dialog .modal-content .modal_btns {
  margin-top: 10px;
}
@media screen and (min-width: 479px) {
  .modal--competition .modal-dialog .modal-content .modal_btns {
    margin-top: 30px;
  }
}
.modal--competition .modal-dialog .modal-content .modal_btns .holder {
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
.modal--competition .modal-dialog .modal-content .modal_btns .holder .button {
  min-width: 120px;
}
@media screen and (min-width: 768px) {
  .modal--competition .modal-dialog .modal-content .modal_btns .holder .button {
    min-width: 134px;
  }
}
.modal--low_margin .modal-dialog, .modal--stop_out .modal-dialog {
  padding: 0;
  margin: 0;
}
@media screen and (min-width: 350px) {
  .modal--low_margin .modal-dialog, .modal--stop_out .modal-dialog {
    width: 350px;
  }
}
@media screen and (min-width: 980px) {
  .modal--low_margin .modal-dialog, .modal--stop_out .modal-dialog {
    width: 900px;
    height: 680px;
  }
}
.modal--low_margin .modal-dialog .close, .modal--stop_out .modal-dialog .close {
  position: absolute;
  top: 20px;
  right: 20px;
  text-shadow: none;
  opacity: 1;
  color: #18191d;
  font-size: 42px;
  font-weight: 100;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
@media screen and (min-width: 1024px) {
  .modal--low_margin .modal-dialog .close, .modal--stop_out .modal-dialog .close {
    top: 5px;
    right: 10px;
  }
}
.modal--low_margin .modal-dialog .close:hover, .modal--stop_out .modal-dialog .close:hover {
  cursor: pointer;
  font-weight: 200;
}
.modal--low_margin .modal-dialog .modal-content, .modal--stop_out .modal-dialog .modal-content {
  color: #18191d;
  text-align: center;
  background: #fff;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  padding: 50px 40px 40px 40px;
}
.modal--low_margin .modal-dialog .modal-content .title, .modal--stop_out .modal-dialog .modal-content .title {
  font-size: 18px;
  color: #18191d;
  font-weight: 700;
  line-height: 31px;
  text-align: center;
  margin: 0px auto;
  max-width: 400px;
}
@media screen and (min-width: 479px) {
  .modal--low_margin .modal-dialog .modal-content .title, .modal--stop_out .modal-dialog .modal-content .title {
    font-size: 24px;
  }
}
@media screen and (max-width: 979px) {
  .modal--low_margin .modal-dialog .modal-content .title, .modal--stop_out .modal-dialog .modal-content .title {
    max-width: 180px;
  }
}
.modal--low_margin .modal-dialog .modal-content .notice, .modal--stop_out .modal-dialog .modal-content .notice {
  margin: 30px auto;
  max-width: 270px;
}
@media screen and (min-width: 980px) {
  .modal--low_margin .modal-dialog .modal-content .notice, .modal--stop_out .modal-dialog .modal-content .notice {
    max-width: 630px;
  }
}
.modal--low_margin .modal-dialog .modal-content .notice p, .modal--stop_out .modal-dialog .modal-content .notice p {
  color: #18191d;
  opacity: 0.5;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
}
@media screen and (min-width: 980px) {
  .modal--low_margin .modal-dialog .modal-content .notice p, .modal--stop_out .modal-dialog .modal-content .notice p {
    max-width: 630px;
    font-size: 18px;
    line-height: 30px;
    opacity: 1;
  }
}
.modal--low_margin .modal-dialog .modal-content .videoholder, .modal--stop_out .modal-dialog .modal-content .videoholder {
  position: relative;
}
.modal--low_margin .modal-dialog .modal-content .videoholder video, .modal--stop_out .modal-dialog .modal-content .videoholder video {
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.25);
  max-width: 100%;
  border-radius: 5px;
  overflow: hidden;
}
.modal--low_margin .modal-dialog .modal-content .modal_btns, .modal--stop_out .modal-dialog .modal-content .modal_btns {
  margin-top: 10px;
}
@media screen and (min-width: 479px) {
  .modal--low_margin .modal-dialog .modal-content .modal_btns, .modal--stop_out .modal-dialog .modal-content .modal_btns {
    margin-top: 30px;
  }
}
.modal--low_margin .modal-dialog .modal-content .modal_btns .holder, .modal--stop_out .modal-dialog .modal-content .modal_btns .holder {
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
.modal--low_margin .modal-dialog .modal-content .modal_btns .holder .button, .modal--stop_out .modal-dialog .modal-content .modal_btns .holder .button {
  min-width: 120px;
}
@media screen and (min-width: 768px) {
  .modal--low_margin .modal-dialog .modal-content .modal_btns .holder .button, .modal--stop_out .modal-dialog .modal-content .modal_btns .holder .button {
    min-width: 134px;
  }
}

#refer_modal #referralURL {
  position: absolute;
  top: -99999px;
}

html, html *:not(input, textarea) {
  -ms-content-zooming: none;
  -ms-touch-action: pan-x pan-y;
  -webkit-content-zooming: none;
  content-zooming: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

body {
  color: white;
  background: #18191d;
}
body .react-grid-item a:focus {
  outline: transparent;
}
body.ios {
  /* CSS specific to iOS devices */
}
@media screen and (max-width: 1023px) {
  body.ios {
    margin-top: env(safe-area-inset-top) !important;
    margin-top: constant(safe-area-inset-top);
  }
}
@media screen and (max-width: 1023px) {
  body.ios .language_switch {
    margin-top: env(safe-area-inset-top) !important;
    margin-top: constant(safe-area-inset-top);
  }
}
body.ios .login {
  padding-top: 100px;
}
body.android {
  /* CSS for android */
}
@media screen and (max-width: 1023px) {
  body.android {
    padding-top: env(safe-area-inset-top) !important;
    padding-top: constant(safe-area-inset-top);
  }
}
@media screen and (max-width: 1023px) {
  body.android .instruments-drawer, body.android .close-drawer, body.android .side-drawer {
    padding-top: env(safe-area-inset-top) !important;
    padding-top: constant(safe-area-inset-top);
  }
}
body.modal-open, body.ReactModal__Body--open {
  overflow: hidden;
}
body.modal-open #app #main #mainContainer, body.modal-open #app #main .sidemenu, body.ReactModal__Body--open #app #main #mainContainer, body.ReactModal__Body--open #app #main .sidemenu {
  filter: blur(10px);
}
body.modal-open #app #main .login, body.ReactModal__Body--open #app #main .login {
  filter: blur(10px);
}

@media screen and (max-width: 1023px) {
  html, body {
    overflow: hidden;
    position: relative;
  }
}

html, body, #app, #main {
  height: 100%;
  background-color: #18191d;
  width: 100%;
  max-width: 100%;
}

.container-fluid {
  height: 100%;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (max-width: 1023px) {
  .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
}

ul {
  -webkit-padding-start: 0;
  -moz-padding-start: 0;
}

.link.link--unstyled {
  color: unset;
}
.link.link--unstyled:hover {
  color: unset;
  text-decoration: none;
}

button {
  border: 0;
  padding: 0;
  outline: none;
  background: transparent;
}
button:focus {
  outline: none;
}

.pill {
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  padding: 0.1rem 0.3rem;
  margin: 0 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pill.blue {
  background: linear-gradient(108.6deg, #3BA4E8 0%, #0A579E 62.59%);
}
.pill span {
  font-weight: 700;
  color: white;
}

/* ==========================================================================
     HELPER CLASSES NOT DEPENDENT ON ANY SPECIFIC COMPONENT / MODULE
     ========================================================================== */
.content-box {
  background-color: transparent;
  height: 100%;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .content-box {
    background-color: #1d2024;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
    border: 1.5px solid #2A2E31;
  }
}

.vert-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

.centerContent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-flex-flow: column;
  -moz-flex-flow: column;
  -ms-flex-flow: column;
  flex-flow: column;
}

@supports (-webkit-appearance: none) {
  .os-android .vh-100 {
    min-height: calc(100vh - 56px) !important;
  }
}

.fullHeight {
  height: 100%;
}

.loginFormOpen {
  display: none;
}
@media screen and (max-width: 1023px) {
  .loginFormOpen {
    display: block;
    width: 90%;
    background-color: #83CA39;
    border: 2px solid #83CA39;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
    padding: 13px;
    color: #ffffff;
    outline: none;
  }
}

.d-hide {
  display: none !important;
}

.d-inblock {
  display: inline-block;
}

.registerMain {
  width: 100vw;
  max-width: 100%;
}
@media screen and (max-width: 1023px) {
  .registerMain .registerMain {
    margin-right: 0;
  }
}
@media screen and (max-width: 1023px) {
  .registerMain .registerMain > .col-lg-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    -moz-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.row.fullWidth {
  width: 100%;
}

@supports (-webkit-appearance: none) {
  .os-android .page-header {
    min-height: calc(100vh - 56px);
  }
}

@media (max-width: 575px) {
  .box-left-padding {
    padding-bottom: 25px;
  }
  .form-add {
    width: 100% !important;
  }
}
.wt-scroll {
  overflow-y: auto;
}
.wt-scroll::-webkit-scrollbar {
  width: 8px;
}
.wt-scroll::-webkit-scrollbar-thumb {
  background-color: #646d7b;
  outline: 1px solid #2c3036;
  border-radius: 5px;
}
.wt-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #2c3036;
  box-shadow: inset 0 0 6px #2c3036;
  border-radius: 5px;
}

.wt-scroll--account-statement {
  max-height: 80vh;
  position: relative;
}
@media screen and (min-width: 1024px) {
  .wt-scroll--account-statement {
    max-height: calc(100vh - 284px);
  }
}

.wt-x-scroll {
  overflow-x: auto;
}
.wt-x-scroll::-webkit-scrollbar {
  width: 8px;
}
.wt-x-scroll::-webkit-scrollbar-thumb {
  background-color: #646d7b;
  outline: 1px solid #2c3036;
  border-radius: 5px;
}
.wt-x-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #2c3036;
  box-shadow: inset 0 0 6px #2c3036;
  border-radius: 5px;
}

@media screen and (max-width: 1023px) {
  .scroll {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -moz-flex-wrap: nowrap;
    -ms-flex-wrap: none;
    flex-wrap: nowrap;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}

@media screen and (max-width: 1023px) {
  .logo-brand {
    height: 39px !important;
    padding: 5px;
    width: auto;
  }
}

@media screen and (max-width: 1023px) {
  .vjs-big-play-button {
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

/* ==========================================================================
     LAYOUT HELPER CLASSES
     ========================================================================== */
.flex-1 {
  flex: 1 !important;
}

iframe#webWidget {
  padding-top: env(safe-area-inset-top);
}

/********************* 1120px ***************************/
@media screen and (max-width: 1023px) {
  /*General*/
  .m-xy-0 {
    margin: 0;
  }
  .m-flex-33 {
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .m-show {
    display: block !important;
  }
  .m-flex {
    display: flex !important;
  }
  .m-inblock {
    display: inline-block !important;
  }
  .block-1 {
    max-width: 100%;
  }
  .block-2 {
    max-width: 305px;
  }
  button:focus {
    outline: none !important;
  }
  .register-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -moz-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  .m-content-center {
    height: 100%;
    position: absolute;
    z-index: 100;
    width: 100%;
    background: #18191d;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    -webkit-align-content: center;
    -moz-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .m-hide {
    display: none !important;
  }
  .m-p-0 {
    padding: 0;
  }
  /*footer*/
  .pl-mob {
    padding-left: 0;
  }
  .pr-mob {
    padding-right: 0;
  }
  .rotate-90 {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .m-width {
    padding: 0;
  }
  .m-flex-50 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .m-pos-1 {
    order: 1;
    height: 124px !important;
    padding-top: 0 !important;
    min-height: 124px;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 2;
  }
  /************Settings******************/
}
@media screen and (max-width: 1023px) and (min-width: 600px) {
  .m-pos-1 {
    height: 147px !important;
  }
}
@media screen and (max-width: 1023px) and (max-height: 640px) {
  .m-pos-1 {
    height: 115px !important;
    min-height: 115px !important;
  }
}
@media screen and (max-width: 1023px) {
  .m-flex-3 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    flex: 0 0 25% !important;
    max-width: 25% !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .mobile-margin {
    padding: 0 !important;
  }
  .mobile-100 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0 !important;
  }
}
@media screen and (max-width: 570px) {
  .m-pos-2 {
    min-height: 280px;
  }
}
.language_switch.desktop {
  position: absolute;
  top: 55px;
  right: 40px;
}
@media screen and (max-width: 979px) {
  .language_switch.desktop {
    display: none;
  }
}
@media screen and (max-height: 530px) {
  .language_switch .dropup button.dropdown-toggle {
    background-color: transparent;
    width: 100%;
    padding: 0;
    height: 20px;
  }
}
@media screen and (max-height: 530px) {
  .language_switch .dropup button.dropdown-toggle span.lang {
    display: none;
  }
}
@media screen and (max-width: 980px) {
  .language_switch .dropup button.dropdown-toggle span.lang {
    display: none;
  }
}
.language_switch .dropup .dropupContainer {
  text-align: right;
}
.language_switch .dropup .dropupContainer.show button.dropdown-toggle {
  width: 158px;
  opacity: 0;
}
.language_switch .dropup .dropupContainer button.dropdown-toggle {
  width: 158px;
  height: 48px;
  line-height: 48px;
  text-align: left;
  font-size: 16px;
  padding: 0 10px;
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
@media screen and (max-width: 979px) {
  .language_switch .dropup .dropupContainer button.dropdown-toggle {
    width: 80px;
  }
}
.language_switch .dropup .dropupContainer button.dropdown-toggle span {
  font-size: 16px;
  margin: 0 0 0 10px;
}
.language_switch .dropup .dropupContainer button.dropdown-toggle span.arrow-down {
  margin-top: -4px;
}
.language_switch .dropup .dropupContainer button.dropdown-toggle span.lang {
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
}
.language_switch .dropup .dropupContainer button.dropdown-toggle i {
  right: 5%;
  left: auto;
  top: 38%;
}
.language_switch .dropup .dropupContainer .dropdown-menu {
  height: calc(100vh - 10px);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: transparent;
  border: none;
  width: 170px;
  min-width: 60px;
  margin-bottom: 0px;
}
.language_switch .dropup .dropupContainer .dropdown-menu a {
  color: white;
  margin-top: 5px;
  padding: 0 0 0 8px;
  width: 158px;
  height: 48px;
  line-height: 48px;
  text-align: left;
  font-size: 16px;
  padding: 0 0 0 10px;
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}
.language_switch .dropup .dropupContainer .dropdown-menu a span {
  font-size: 16px;
  margin: 0 0 0 10px;
  color: #ffffff;
}

.registerButton {
  position: relative;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.25);
  border-color: rgba(255, 255, 255, 0.6);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}
@media screen and (max-width: 1023px) {
  .registerButton {
    background-color: #00A1DF;
    border-radius: 30px;
    border: none;
  }
}
.registerButton:before {
  content: "";
  display: block;
  padding-top: 100%;
}
@media screen and (max-width: 1023px) {
  .registerButton:before {
    padding-top: 50px;
  }
}
.registerButton__holder {
  padding: 15px;
  width: 100%;
  max-width: 100%;
  margin: auto;
}
@media screen and (min-width: 1024px) {
  .registerButton__holder {
    width: 200px;
  }
}
.registerButton .btnContent {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60% auto;
}
@media screen and (max-width: 1023px) {
  .registerButton .btnContent {
    background-image: none !important;
  }
}
.registerButton .btnDesc {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
  left: 0;
  color: #ffffff;
  font-weight: 700;
}
@media screen and (max-width: 1023px) {
  .registerButton .btnDesc {
    font-weight: 400;
    font-size: 18px;
    line-height: 52px;
    bottom: 0;
  }
}
@media screen and (max-width: 600px) {
  .registerButton .btnDesc, .registerButton .btnDescLogin {
    font-size: 16px;
  }
}
@media screen and (max-width: 480px) {
  .registerButton .btnDesc, .registerButton .btnDescLogin {
    font-size: 12px;
  }
}
@media screen and (max-width: 1024px) {
  .registerButton .btnDescLogin {
    font-size: 18px;
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 19px;
  /* Hide default HTML checkbox */
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.vertical-bar-blue {
  background-color: #1496cd;
  margin-top: 0;
  height: 100% !important;
  position: absolute !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  overflow: visible !important;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  height: 0;
}
@media screen and (min-width: 1024px) {
  .vertical-bar-blue {
    display: none;
  }
}
.vertical-bar-blue i {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  color: #ffffff;
  background-color: #1496cd;
  left: -8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  font-size: 0.8125rem;
  padding: 5px;
}
.vertical-bar-blue span {
  padding: 0 20px;
  overflow: hidden;
  word-break: break-word;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  height: 25px;
}
@media screen and (max-width: 599px) {
  .vertical-bar-blue span {
    font-size: 12px;
    height: 19px;
  }
}
@media screen and (max-width: 1023px) {
  .vertical-bar-blue__overlay {
    position: absolute !important;
  }
}

@media screen and (max-width: 1023px) {
  .footer {
    text-align: center;
  }
}
@media screen and (min-width: 1024px) {
  .footer {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  .footer__lost {
    font-size: 12px;
    top: 4px;
    position: relative;
    opacity: 0.5;
    max-width: 247px;
    margin: 0 auto 20px auto;
  }
}
@media screen and (max-width: 479px) {
  .footer__lost {
    font-size: 10px;
    top: 0;
    position: static;
  }
}
@media screen and (max-width: 1023px) {
  .footer__links a {
    text-decoration: none;
    font-size: 12px;
    padding: 0 15px;
    color: #ffffff;
  }
}
@media screen and (max-width: 1023px) {
  .footer__links a:nth-child(2) {
    text-transform: uppercase;
  }
}
@media screen and (max-width: 1023px) {
  .footer__links span {
    opacity: 0.15;
  }
}
.footer .powered-by {
  margin-top: 1rem;
  text-align: center;
}
.footer .powered-by__label {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.5);
}
.footer .powered-by__link {
  font-size: 14px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.5);
  line-height: 1;
}
.footer .powered-by__link:hover {
  text-decoration: none;
  color: #12aaeb;
}

.back-button {
  cursor: pointer;
  height: 42px;
  min-height: 42px;
  width: 42px;
  min-width: 42px;
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.back-button__icon {
  width: 16px;
}

.page-title__title {
  margin: 0 0 1.5rem;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.date-range-header {
  min-height: 42px;
  margin-bottom: 15px;
  padding: 10px 20px;
  background: linear-gradient(134.22deg, #565a5c -63.52%, #2a2e31 74.66%);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  position: relative;
  font-size: 14px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}

.trade-result {
  text-align: center;
  width: 100%;
  margin-top: 20px;
}
@media screen and (max-width: 1023px) {
  .trade-result {
    font-size: 14px;
    text-align: center;
    width: 100%;
    margin-top: 0;
  }
}
.trade-result > span {
  display: inline-block;
  padding: 5px 10px 5px 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  background-color: #18191d;
}
@media screen and (max-width: 1023px) {
  .trade-result > span {
    display: inline-block;
    padding: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
  }
}
.trade-result.win > span {
  background-color: #4bb84e;
  width: 90%;
  margin-bottom: 5px;
}
@media screen and (max-width: 1500px) {
  .trade-result.win > span {
    width: auto;
    min-width: 50%;
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 1023px) {
  .trade-result.win > span {
    width: 100%;
    min-width: 100%;
    margin-bottom: 0;
    font-weight: 400;
    background-color: #98B946;
  }
}
.trade-result.loss > span {
  background-color: #f5004d;
  width: 90%;
  margin-bottom: 5px;
}
@media screen and (max-width: 1500px) {
  .trade-result.loss > span {
    width: auto;
    min-width: 50%;
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 1023px) {
  .trade-result.loss > span {
    width: 100%;
    min-width: 100%;
    margin-bottom: 0;
    font-weight: 400;
  }
}

.positions-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
}
@media screen and (max-width: 1023px) {
  .positions-list {
    -webkit-flex-wrap: nowrap;
    -moz-flex-wrap: nowrap;
    -ms-flex-wrap: none;
    flex-wrap: nowrap;
    overflow: auto;
    overflow-scrolling: touch;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    width: 100vw;
    max-width: 100%;
    margin-bottom: 10px;
    height: auto;
    background-color: transparent;
  }
}
@media screen and (max-height: 640px) {
  .positions-list {
    margin-bottom: 2.5px;
  }
}
@media screen and (max-width: 1023px) {
  .positions-list::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
  }
}
.positions-list .counter-bubble {
  background-color: #f5004d;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  display: block;
  margin-left: 1rem;
}

.refer-tooltip .react-tooltip-lite {
  box-shadow: 5px 8px 14px 0 black;
  background: #24262b;
  border: 1px solid #2a2e31;
  border-radius: 9px;
  color: white;
  margin-top: 24.5px;
  z-index: 1070 !important;
}
.refer-tooltip .react-tooltip-lite .copied-label {
  margin: 0 0 17px 0;
  font-size: 16px;
}
.refer-tooltip .react-tooltip-lite-arrow {
  margin-top: 24.5px;
  border-color: #18191d;
}
.refer-tooltip .react-tooltip-lite .referral-link-input {
  border: 1px solid #2a2e31;
  color: #7e868f;
  padding: 17px 15px;
  background: #18191d;
  width: 280px;
  height: 48px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  font-size: 14px;
}

.trend-indicator-tooltip .react-tooltip-lite {
  background: rgba(0, 0, 0, 0.9);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
  color: white;
}
.trend-indicator-tooltip .react-tooltip-lite-arrow {
  border-color: rgba(0, 0, 0, 0.9);
}
.trend-indicator-tooltip .react-tooltip-lite .content-wrapper {
  font-size: 14px;
  max-width: 400px;
}
.trend-indicator-tooltip .react-tooltip-lite .content-wrapper .indicator-description {
  margin-left: 14px;
  margin-bottom: 0;
}
.trend-indicator-tooltip .react-tooltip-lite .content-wrapper .indicator-description:before {
  width: 9px;
  height: 9px;
  position: static;
  top: 0px;
  margin-right: 5px;
  margin-left: -14px;
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  content: "";
}
.trend-indicator-tooltip .react-tooltip-lite .content-wrapper .indicator-description--sell:before {
  background: #CD0000;
}
.trend-indicator-tooltip .react-tooltip-lite .content-wrapper .indicator-description--buy:before {
  background: #55D636;
}

.react-orientation {
  background-color: #18191d;
  display: flex;
  flex-direction: row;
  color: #ffffff;
  font-weight: 200;
  height: 100%;
}

.device-orientation-wrapper {
  height: 100%;
}

.total-footer {
  min-height: 40px;
  margin: 10px 0 0 0;
  background-color: transparent;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  overflow: hidden;
}
@media screen and (min-width: 1024px) {
  .total-footer {
    margin: 20px 0 0 0;
    padding: 0 30px;
  }
}
.total-footer__amount {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  padding: 10px 20px;
}
@media screen and (max-width: 1024px) {
  .total-footer__amount {
    padding: 6px 20px;
  }
}
.total-footer__amount.positive {
  background: linear-gradient(103.09deg, #70c157 21.7%, #3e8c3b 59.43%);
}
.total-footer__amount.negative {
  background: linear-gradient(103.09deg, #f60057 21.7%, #bb173e 59.43%);
}
.total-footer__amount span {
  font-weight: 700;
  font-size: 16px;
}
.total-footer__label {
  font-size: 16px;
  font-weight: 700;
}
@media screen and (max-width: 1023px) {
  .total-footer__label:after {
    content: ":";
  }
}

.mobile-page-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch;
  margin: 10px 0;
  position: relative;
  min-height: 42px;
}
@media screen and (min-width: 1024px) {
  .mobile-page-header {
    display: none;
  }
}
.mobile-page-header__logo {
  text-align: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.mobile-page-header .back-button {
  position: absolute;
  top: 0;
  left: 10px;
}

.messages-icon {
  position: relative;
}
.messages-icon__unread-badge {
  position: absolute;
  background-color: #70c157;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  top: 3px;
  right: -2px;
}

.message-item {
  border: 1px solid #2a2e31;
  border-radius: 5px;
  padding: 16px 24px;
  display: flex;
  margin: 0.5rem 0;
  color: white;
  flex-direction: column;
}
.message-item:hover {
  text-decoration: none;
  color: white;
}
@media screen and (max-width: 1023px) {
  .message-item {
    flex-direction: column-reverse;
    padding: 15px 20px;
  }
}
.message-item:first-child {
  margin-top: 0;
}
.message-item__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.message-item__title {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0;
  flex: 1;
  min-width: 0;
  text-overflow: ellipsis;
  max-lines: 2;
}
.message-item__content p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: regular;
  color: #A6A6A8;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.message-item__end {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1023px) {
  .message-item__end {
    margin: 0 0 0.5rem;
    justify-content: space-between;
  }
}
.message-item__end .message-item__date {
  font-size: 12px;
  color: #70c157;
}
.message-item__end .message-item__unread-badge {
  width: 8px;
  height: 8px;
  background-color: #2a2e31;
  border-radius: 100%;
  margin-left: 1rem;
}
.message-item--unread {
  background-color: #2a2e31;
}
.message-item--unread .message-item__unread-badge {
  background-color: #70c157;
}

.maintenance-screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  position: absolute;
}
.maintenance-screen__logo {
  max-width: 190px;
  position: absolute;
  top: 3rem;
}
.maintenance-screen__icon {
  margin-top: -150px;
}
.maintenance-screen__title {
  margin-top: -150px;
  color: white;
}
.maintenance-screen__description {
  margin: 1rem 1rem;
  text-align: center;
  max-width: 450px;
  opacity: 0.6;
}
.maintenance-screen__button {
  margin-top: 1rem;
}

.no-messages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.no-messages__icon {
  margin-top: 5rem;
  margin-bottom: 4rem;
}

li.tab {
  border: 1px solid #2a2e31;
  border-radius: 5px 5px 0 0;
  background: #2a2b31;
  padding: 0.5rem 1rem;
  margin: 0 0.1rem;
  cursor: pointer;
}
li.tab > * {
  opacity: 0.35;
}
li.tab--active {
  background: linear-gradient(134.22deg, #2a2e31 -63.52%, #565a5c 74.66%);
}
li.tab--active > * {
  opacity: 1;
}

.tabs-container ul.tabs {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  margin: 0;
}
.tabs-container .content {
  background: #1d2024;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.notice {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2rem 0;
}
.notice__icon {
  margin-bottom: 2.5rem;
}
.notice__subtitle {
  font-size: 18px;
  color: #A7A8A8;
}
.notice__button {
  margin-top: 2rem !important;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.react-tabs__tab {
  display: inline-block;
  bottom: -1px;
  position: relative;
  list-style: none;
  cursor: pointer;
  background: linear-gradient(134.22deg, #565a5c -63.52%, #2a2e31 74.66%);
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: #2a2e31;
  border-radius: 10px 10px 0 0;
  color: white;
  margin-right: 0.5rem;
  width: 120px;
  text-align: center;
  padding: 0.5rem 0.2rem;
  font-size: 16px;
}

.react-tabs__tab--selected {
  background: #1d2024;
  border-color: #2a2e31;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
  background: #1d2024;
  border: 1px solid #2a2e31;
  border-radius: 5px;
  overflow: auto;
  max-height: 100%;
  padding: 2rem;
}
@media screen and (max-width: 1023px) {
  .react-tabs__tab-panel--selected {
    padding: 2rem 1rem;
  }
}

.amount-label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  overflow: hidden;
}
@media screen and (min-width: 1024px) {
  .amount-label:nth-child(1) {
    margin: 0 0 10px 0;
  }
}
.amount-label__title {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 50%;
  -moz-box-flex: 0;
  -moz-flex: 0 1 50%;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
  padding: 10px 20px 10px 20px;
  background: #2a2e31;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  font-size: 12px;
  color: #fff;
}
.amount-label__price {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 50%;
  -moz-box-flex: 0;
  -moz-flex: 0 1 50%;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
  padding: 10px 20px 10px 12px;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
}
.amount-label__price--green {
  background: linear-gradient(103.09deg, #70C157 21.7%, #3E8C3B 59.43%), #3E8C3B;
}
.amount-label__price--red {
  background: linear-gradient(103.09deg, #F60057 21.7%, #BB173E 59.43%), #3E8C3B;
}

.page-subtitle {
  text-align: center;
  margin-bottom: 1.2rem;
}
.page-subtitle__subtitle {
  font-size: 14px;
  opacity: 0.6;
}

.logout_btn {
  color: white;
  display: block;
  text-align: center;
  padding-top: 5px;
}
.logout_btn i {
  font-size: 42px;
}

.segmented-controls {
  position: relative;
  z-index: 1030;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
@media screen and (min-width: 1024px) {
  .segmented-controls {
    flex-direction: column;
  }
}
.segmented-controls__active {
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  position: relative;
  height: 42px;
  width: 42px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  z-index: 3;
}
.segmented-controls__active span {
  font-size: 12px;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.segmented-controls__active:hover {
  background: #212327;
  cursor: pointer;
}
.segmented-controls__active.disabled {
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  cursor: initial;
}
.segmented-controls__choices {
  margin: 0 0 0 5px;
  padding: 0;
  background: #212327;
  border: 1.5px solid #2a2e31;
  list-style-type: none;
  overflow: hidden;
  height: 42px;
  border-radius: 5px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: none;
}
@media screen and (min-width: 1024px) {
  .segmented-controls__choices {
    flex-direction: column;
    position: absolute;
    top: 48px;
    margin: 0;
    width: 42px;
    height: max-content;
  }
}
@media screen and (max-width: 1023px) {
  .segmented-controls__choices {
    transform: translateX(-15%);
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
  }
}
@media screen and (min-width: 1024px) {
  .segmented-controls__choices {
    transform: translateY(-15%);
    animation: slide-down 0.5s forwards;
    -webkit-animation: slide-down 0.5s forwards;
  }
}
.segmented-controls__choices.expanded {
  display: flex;
}
.segmented-controls__choices .choice {
  margin: 0;
  position: relative;
  width: 42px;
  height: 42px;
  text-decoration: none;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -moz-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-transition: background 0.25s ease;
  -moz-transition: background 0.25s ease;
  -ms-transition: background 0.25s ease;
  -o-transition: background 0.25s ease;
  transition: background 0.25s ease;
}
@media screen and (min-width: 1024px) {
  .segmented-controls__choices .choice {
    padding: 10px 0;
  }
}
@media screen and (max-width: 1023px) {
  .segmented-controls__choices .choice {
    min-height: 42px;
    padding: 0;
  }
}
.segmented-controls__choices .choice span {
  font-size: 12px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}
.segmented-controls__choices .choice:hover {
  background: linear-gradient(134.22deg, #565A5C -63.52%, #2A2E31 74.66%), #2A2E31;
  cursor: pointer;
}

@keyframes slide-in {
  100% {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -o-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@-webkit-keyframes slide-in {
  100% {
    -webkit-transform: translateX(0%);
  }
}
@keyframes slide-down {
  100% {
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -o-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
  }
}
@-webkit-keyframes slide-down {
  100% {
    -webkit-transform: translateY(0%);
  }
}
.stat-box {
  background: #2a2b31;
  border: 1px solid #383d44;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.stat-box__title-holder .title {
  color: white;
  font-size: 14px;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 20px;
}
.stat-box__value-holder .value {
  color: white;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  line-height: 1;
}
.stat-box--provider .stat-box__value-holder {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  align-items: center;
}
.stat-box--provider .stat-box__value-holder .value {
  color: white;
  font-size: 14px;
  font-weight: 500;
}
.stat-box--provider .stat-box__value-holder .avatar {
  max-width: 30px;
  height: auto;
  border-radius: 50%;
}
.stat-box--green .stat-box__value-holder .value {
  color: #70c157;
}
.stat-box--green .stat-box__value-holder .value {
  color: #70c157;
}
.stat-box--large_title .stat-box__title-holder .title {
  color: white;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
.stat-box--large_title .stat-box__value-holder .value {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 300;
}
@media screen and (min-width: 768px) {
  .stat-box--half {
    flex: 2;
  }
}

.table__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  color: white;
}
.table__row .label {
  font-size: 14px;
  font-weight: 300;
  margin: 0;
}
.table__row .value-unit {
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}
.table--alternative .table__row .label {
  font-size: 14px;
  font-weight: 700;
}
.table--alternative .table__row .value-unit {
  font-size: 14px;
  font-weight: 300;
}

[type=range] {
  -webkit-appearance: none;
  background: transparent;
  margin: 12px 0;
  width: 100%;
}
[type=range]::-moz-focus-outer {
  border: 0;
}
[type=range]:focus {
  outline: 0;
}
[type=range]:focus::-webkit-slider-runnable-track {
  background: rgb(250.6818181818, 251.3636363636, 251.8181818182);
}
[type=range]:focus::-ms-fill-lower {
  background: #eceff1;
}
[type=range]:focus::-ms-fill-upper {
  background: rgb(250.6818181818, 251.3636363636, 251.8181818182);
}
[type=range]::-webkit-slider-runnable-track {
  cursor: default;
  height: 8px;
  transition: all 0.2s ease;
  width: 100%;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2), 0 0 1px rgba(12.75, 12.75, 12.75, 0.2);
  background: #eceff1;
  border: 2px solid #cfd8dc;
  border-radius: 5px;
}
[type=range]::-webkit-slider-thumb {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), 0 0 4px rgba(12.75, 12.75, 12.75, 0.2);
  background: #607d8b;
  border: 2px solid #eceff1;
  border-radius: 12px;
  box-sizing: border-box;
  cursor: default;
  height: 24px;
  width: 24px;
  -webkit-appearance: none;
  margin-top: -10px;
}
[type=range]::-moz-range-track {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2), 0 0 1px rgba(12.75, 12.75, 12.75, 0.2);
  cursor: default;
  height: 8px;
  transition: all 0.2s ease;
  width: 100%;
  background: #eceff1;
  border: 2px solid #cfd8dc;
  border-radius: 5px;
  height: 4px;
}
[type=range]::-moz-range-thumb {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), 0 0 4px rgba(12.75, 12.75, 12.75, 0.2);
  background: #607d8b;
  border: 2px solid #eceff1;
  border-radius: 12px;
  box-sizing: border-box;
  cursor: default;
  height: 24px;
  width: 24px;
}
[type=range]::-ms-track {
  cursor: default;
  height: 8px;
  transition: all 0.2s ease;
  width: 100%;
  background: transparent;
  border-color: transparent;
  border-width: 12px 0;
  color: transparent;
}
[type=range]::-ms-fill-lower {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2), 0 0 1px rgba(12.75, 12.75, 12.75, 0.2);
  background: rgb(221.3181818182, 226.6363636364, 230.1818181818);
  border: 2px solid #cfd8dc;
  border-radius: 10px;
}
[type=range]::-ms-fill-upper {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2), 0 0 1px rgba(12.75, 12.75, 12.75, 0.2);
  background: #eceff1;
  border: 2px solid #cfd8dc;
  border-radius: 10px;
}
[type=range]::-ms-thumb {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2), 0 0 4px rgba(12.75, 12.75, 12.75, 0.2);
  background: #607d8b;
  border: 2px solid #eceff1;
  border-radius: 12px;
  box-sizing: border-box;
  cursor: default;
  height: 24px;
  width: 24px;
  margin-top: 2px;
}
[type=range]:disabled::-webkit-slider-thumb, [type=range]:disabled::-moz-range-thumb, [type=range]:disabled::-ms-thumb, [type=range]:disabled::-webkit-slider-runnable-track, [type=range]:disabled::-ms-fill-lower, [type=range]:disabled::-ms-fill-upper {
  cursor: not-allowed;
}