/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
.wrapper {
  height: 42px;
  min-width: 0;
}
@media screen and (min-width: 766px) {
  .wrapper {
    height: 52px;
  }
}

.logo {
  height: 100%;
  width: auto;
  max-width: 100%;
  object-fit: contain;
}