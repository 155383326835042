.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.image-wrapper {
  padding: 1rem;
  margin-bottom: 2rem;
  position: relative;
}
.image-wrapper:before {
  content: "";
  position: absolute;
  background-image: url("~/src/old/icons/lines-background.svg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 500px;
  height: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
}

.description {
  text-align: center;
}

.button {
  margin-top: 1rem;
}