html,
body {
  font-size: 16px;
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**************************************************
    GRID & LAYOUT SETTINGS
    **********************************************/
/**************************************************
    COLORS
    **********************************************/
/**************************************************
    BORDER RADIUS DEFINITIONS
    **********************************************/
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container--bronze .progress-label-wrapper {
  border-color: var(--loyalty-bronze, #E36629);
}
.container--bronze .progress-bar {
  background: var(--loyalty-bronze, #E36629);
}
.container--silver .progress-label-wrapper {
  border-color: var(--loyalty-silver, #BCB8B7);
}
.container--silver .progress-bar {
  background: var(--loyalty-silver, #BCB8B7);
}
.container--gold .progress-label-wrapper {
  border-color: var(--loyalty-gold, #F8A300);
}
.container--gold .progress-bar {
  background: var(--loyalty-gold, #F8A300);
}
.container--platinum .progress-label-wrapper {
  border-color: var(--loyalty-platinum, #EEEEEE);
}
.container--platinum .progress-bar {
  background: var(--loyalty-platinum, #EEEEEE);
}
.container--diamond .progress-label-wrapper {
  border-color: var(--loyalty-diamond, #A3F4FF);
}
.container--diamond .progress-bar {
  background: var(--loyalty-diamond, #A3F4FF);
}

.level-title {
  margin: 0.5rem 0;
  text-align: center;
}

.progress-stat {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progress-label-wrapper {
  border: 1.5px solid transparent;
  background: #18191d;
  border-radius: 31px;
  text-align: center;
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-stat-label {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  margin-top: 0.5rem;
}

.progress-label {
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.5);
  display: block;
  flex: 1;
  min-width: 100px;
  text-align: start;
}
.progress-label--accent {
  font-weight: 700;
  color: #FFF;
  text-align: end;
}
.progress-label--separator {
  min-width: unset;
}

.progress-bar-wrapper {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: stretch;
  width: 100%;
  max-width: 350px;
}

.progress-bar-track {
  position: relative;
  height: 16px;
  background-color: #18191d;
  border-radius: 12px;
  overflow: hidden;
  flex: 1;
  margin: 0 1rem;
}

.progress-bar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.notice-wrapper {
  margin-top: 1rem;
  display: flex;
}

.heads-up-label-wrapper {
  white-space: nowrap;
}

.heads-up-label {
  background: #CD0000;
  border-radius: 31px;
  font-size: 0.6875rem;
  font-weight: 700;
  padding: 0.2rem 0.5rem;
  margin-right: 0.5rem;
}

.notice {
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.5);
}
.notice--accent {
  color: #FFF;
  font-weight: 700;
}